import jsonpatch from "fast-json-patch"

import { DataModel } from "../../types"
import { APIService } from "./api.service"

export default class ActualValueDataService extends APIService {
  private url = "/api/actualValues"

  async loadActualValuesForElementTypeMonthAndYear(month: number, year: number, elementType: number, clearExistingValuesIfPresent: boolean): Promise<DataModel[]> {
    const response = await this.api.post(`${this.url}/${month}/${year}/${elementType}?clearExistingValuesIfPresent=${clearExistingValuesIfPresent}`)
    return response.data
  }
  async getActualValuesForElementTypeMonthAndYear(month: number, year: number, elementType: number): Promise<DataModel[]> {
    const response = await this.api.get(`${this.url}/${month}/${year}/${elementType}`)
    return response.data
  }
}
