import React, { useEffect, useRef, useContext, useState } from "react"
import { Link, useLocation } from "react-router-dom"

import { Col, Nav, Row, NavDropdown } from "react-bootstrap"
// import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu"
import { Toast } from "primereact/toast"

import ChevronLogo from "../../assets/images/chevronLogo.jpg"
import { Toaster } from "../../services/toaster.service"
import ProfileImage from "../ProfileImage"

import AppStateContext from "../../state/AppStateContext"
import "./Header.scss"
import "react-bootstrap-submenu/dist/index.css"

const Header: React.FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const handleMouseEnter = () => {
    setIsDropdownOpen(true)
  }

  const handleMouseLeave = () => {
    setIsDropdownOpen(false)
  }

  const appState = useContext(AppStateContext)

  Toaster.init(useRef())

  const locationChange = useLocation()

  useEffect(() => {
    Toaster.clearAllToastMessages()
  }, [locationChange])

  return (
    <>
      <div className="headerDiv">
        <div className="Header">
          <header>
            <Row className="header">
              <Col lg={2}>{/* space holder */}</Col>
              <Col className="logo-conteiner">
                <Link to="/">
                  <img className="header__logo" src={ChevronLogo} alt="logo" />
                </Link>
                <h4 className="header__title">Reporting and Performance Integration Dashboard</h4>
              </Col>
              <Col lg={2} className="header__userInfo">
                <div className="dropdown2 float-end">
                  {/*User Profile Button Icon*/}
                  <button className="dropbtn border-0">
                    <ProfileImage firstName={appState.userProfile.firstName} lastName={appState.userProfile.lastName} oId={appState.userProfile.oId} />
                  </button>

                  {/*User Profile Dropdown Content*/}
                  <div className="row no-gutters align-items-center dropdown-content px-5 py-4">
                    {/*left profile picture*/}
                    <div className="col-auto pr-3 user-profile">
                      <ProfileImage firstName={appState.userProfile.firstName} lastName={appState.userProfile.lastName} oId={appState.userProfile.oId} />
                    </div>
                    <div className="col-auto text-left">
                      {/*right top name*/}
                      <div className="gotham-bold">{appState.userProfile?.firstName + " " + appState.userProfile?.lastName}</div>
                      {/*right bottom email*/}
                      <div className="gotham-narrow-book medium">{appState.userProfile?.email}</div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </header>
          <nav id="site-specific-nav">
            <ul>
              <li>
                <Link to="">administration</Link>
                <ul>
                  <li>
                    <Link to="/admin/business-entities" style={{ marginLeft: "20px" }}>
                      manage business entities
                    </Link>
                  </li>
                  <li>
                    <Link to="" style={{ marginLeft: "20px" }}>
                      manage spend class
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="">business plan</Link>
                <ul>
                  <li>
                    <Link to="/admin/businessplan/load/deseasonalized">load deseasonalized business plan</Link>
                  </li>
                  <li>
                    <Link to="/admin/businesspan/load/seasonalized">load seasonalized business plan</Link>
                  </li>
                  <li>
                    <Link to="/admin/businesspan/search/deseasonalized">search deseasonalized business plans</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
        <Toast ref={Toaster.instance} position="top-center" />
      </div>
    </>
  )
}

export default Header
