import React from "react"
import ReactDOM from "react-dom/client"
import { HashRouter } from "react-router-dom"

import "regenerator-runtime/runtime"
import { PublicClientApplication, InteractionType, SilentRequest } from "@azure/msal-browser"
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react"
import { msalConfig } from "./auth-config"

import App from "./pages/App"
import Error from "./components/Error"
import Loading from "./components/AuthenticationLoading"

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min"
import "bootstrap-icons/font/bootstrap-icons.css"
import "./styles/NextPage.min.css"
import "./index.scss"

//import reportWebVitals from "./reportWebVitals"

console.log(msalConfig)
export const msalInstance = new PublicClientApplication(msalConfig)

const isIFrame = (): boolean => {
  return window !== window.parent && !window.opener
}

const interactionType: InteractionType = isIFrame() ? InteractionType.Silent : InteractionType.Redirect

const authRequest: SilentRequest = {
  scopes: ["openid", "profile"]
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <HashRouter>
      <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate interactionType={interactionType} authenticationRequest={authRequest} errorComponent={Error} loadingComponent={Loading}>
          <App />
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </HashRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals()
