export function findDuplicates(arr) {
    const map = new Map();
    const duplicates = [];

    // Iterate over the array and count the occurrences
    for (const item of arr) {
        if (map.has(item)) {
            map.set(item, map.get(item) + 1);
        } else {
            map.set(item, 1);
        }
    }

    // Find all strings that have more than one occurrence
    for (const [item, count] of map) {
        if (count > 1) {
            duplicates.push(item);
        }
    }

    return duplicates;
}