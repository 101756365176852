import { FC, useRef, useEffect, useMemo, useState } from "react"

import { useImmerReducer } from "use-immer"

import ActionTypes from "../../../state/ActionTypes"
import { UploadDeseasonalizedBusinessPlanStateContext, UploadDeseasonalizedBusinessPlanDispatchContext } from "../../../state/Context"
import { initialUploadDeasonalizedBusinessPlan, uploadBusinessPlanReducer } from "../../../state/UploadDeasonalizedBusinessPlan"

import { BusinessPlanSession } from "../../../types/BusinessPlanSession"
import { BusinessPanType, MIDASBusinessPlanInput } from "../../../types/BusinessPlan"
import { BusinessEntity } from "../../../types"

import useConvertElementTypeValueToString from "../../../hooks/useConvertElementTypeValueToString"
import useConvertVersionNumberToString from "../../../hooks/useConvertVersionNumberToString"
import useConvertRegularBusinessPlanToMIDASBusinessPlanInput from "../../../hooks/useConvertRegularBusinessPlanToMIDASBusinessPlanInput"

import { Message } from "primereact/message"
import { Dialog } from "primereact/dialog"
import { ConfirmDialog } from "primereact/confirmdialog"

import Page from "../../../components/Page"
import Loading from "../../../components/Loading"
import UploadDeasonalizedBusinessPlanForm from "../../../components/UploadDeasonalizedBusinessPlanForm"
import BusinessPlanList from "../../../components/BusinessPlanList"

import BusinessPlanService from "../../../services/api/businessPlan.service"
import BusinessEntityService from "../../../services/api/businessEntity.service"
import { Toaster } from "../../../services/toaster.service"

import "./UploadDeasonalizedBusinessPlan.scss"

interface UploadDeasonalizedBusinessPlanProps { }

const UploadDeasonalizedBusinessPlan: FC<UploadDeasonalizedBusinessPlanProps> = () => {
  const [state, dispatch] = useImmerReducer(uploadBusinessPlanReducer, initialUploadDeasonalizedBusinessPlan)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const formikRef = useRef<any>()
  const fileUploadRef = useRef<any>()

  const businessEntityuService = useMemo(() => new BusinessEntityService(), [])
  const businessPlanService = useMemo(() => new BusinessPlanService(), [])

  const convertElementTypeValueToString = useConvertElementTypeValueToString()
  const convertVersionNumberToString = useConvertVersionNumberToString()
  const convertRegularBusinessPlanToMIDASBusinessPlanInput = useConvertRegularBusinessPlanToMIDASBusinessPlanInput()

  useEffect(() => {
    // When user selects all parameters, we load the last loaded active business plan session. A user will be required to delete the existing one before they can overwrite with new data 
    // We also get all the business entities as we will be validating against those
    if (state.businessPlanSession.year && state.businessPlanSession.elementType && (state.businessPlanSession.revisionNumber || state.businessPlanSession.revisionNumber === 0)) {
      dispatch({ type: ActionTypes.START_LOADING })
      dispatch({ type: ActionTypes.SET_BUSINESS_ENTITIES, value: [] as BusinessEntity[] })
      const session = { ...state.businessPlanSession, id: null, businessPlans: [] }
      dispatch({ type: ActionTypes.SET_BUSINESS_PLAN_SESSION, value: session })
      const tasks = []
      tasks.push(businessPlanService.getLastLoadedBusinessPlanningSession(state.businessPlanSession.year, state.businessPlanSession.elementType, state.businessPlanSession.revisionNumber, BusinessPanType.normal))
      tasks.push(businessEntityuService.getAllBusinessEntities(false, null, null, null, null))
      Promise.all(tasks)
        .then(results => {
          var sessionResult = results[0]
          if (sessionResult && sessionResult.businessPlans) {
            // Business plans come in a different format so we have to map to a view model
            const businessPlans = sessionResult.businessPlans.map(x => convertRegularBusinessPlanToMIDASBusinessPlanInput(x))
            const session = { ...state.businessPlanSession, id: sessionResult.id, businessPlans: businessPlans }
            dispatch({ type: ActionTypes.SET_BUSINESS_PLAN_SESSION, value: session })
            dispatch({ type: ActionTypes.DISABLE_FILE_SELECTION })
          }
          var entitiesResult = results[1]
          if (entitiesResult.totalCount) {
            dispatch({ type: ActionTypes.SET_BUSINESS_ENTITIES, value: entitiesResult.items })
          }
        })
        .catch(() => {
          dispatch({ type: ActionTypes.SET_ERROR_EXISTS, value: true })
        })
        .finally(() => dispatch({ type: ActionTypes.STOP_LOADING }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.businessPlanSession.year, state.businessPlanSession.elementType, state.businessPlanSession.revisionNumber])

  useEffect(() => {
    if (!(state.businessPlanSession.year && state.businessPlanSession.elementType && (state.businessPlanSession.revisionNumber || state.businessPlanSession.revisionNumber === 0) && state.businessEntities.length)) {
      dispatch({ type: ActionTypes.DISABLE_FILE_SELECTION })
      dispatch({ type: ActionTypes.SET_BUSINESS_PLANS, value: [] as MIDASBusinessPlanInput[] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.businessPlanSession.year, state.businessPlanSession.elementType, state.businessPlanSession.revisionNumber, state.businessEntities])

  useEffect(() => {
    if (state.businessPlanSession.year && state.businessPlanSession.elementType && (state.businessPlanSession.revisionNumber || state.businessPlanSession.revisionNumber === 0) && !state.isLoading && !state.businessPlanSession.businessPlans.length) {
      dispatch({ type: ActionTypes.ENABLE_FILE_SELECTION })
      //dispatch({ type: ActionTypes.IS_UPLOAD_DISABLED, value: false })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.businessPlanSession.year, state.businessPlanSession.elementType, state.businessPlanSession.revisionNumber, state.isLoading, state.businessPlanSession.businessPlans])

  function hasErrors(businessPlan) {
    return Object.keys(businessPlan).some(key => (key === "class" ? (businessPlan[key].name.toLowerCase() === "Total".toLowerCase() ? false : businessPlan[key].error) : businessPlan[key].error))
  }

  useEffect(() => {
    if (!state.businessPlanSession.id && state.businessPlanSession.businessPlans.length && !state.businessPlanSession.businessPlans.some(hasErrors)) {
      dispatch({ type: ActionTypes.ENABLE_FILE_UPLOAD })
    } else {
      dispatch({ type: ActionTypes.DISABLE_FILE_UPLOAD })
      if (state.businessPlanSession.businessPlans.some(hasErrors)) {
        setErrorMessage("The file you're attempting to upload contains errors. Please correct these errors and try uploading again.")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.businessPlanSession.id, state.businessPlanSession.businessPlans])

  useEffect(() => {
    if (state.uploadCount) {
      dispatch({ type: ActionTypes.START_LOADING })
      businessPlanService
        .saveDeseasonalizedBusinessPlanSession(state.businessPlanSession)
        .then(result => {
          Toaster.showSuccess("Business plans uploaded successfully")
          dispatch({
            type: ActionTypes.SET_BUSINESS_PLAN_SESSION,
            value: {
              id: null,
              year: null,
              elementType: null,
              revisionNumber: null,
              businessPlans: [] as MIDASBusinessPlanInput[],
              totalValue: 0
            } as BusinessPlanSession
          })
          dispatch({ type: ActionTypes.RESET_PARAMETERS })
        })
        .catch(() => { })
        .finally(() => dispatch({ type: ActionTypes.STOP_LOADING }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.uploadCount])

  useEffect(() => {
    if (state.deleteBusinessPlanSessionCount && state.businessPlanSession.id) {
      dispatch({ type: ActionTypes.START_LOADING })
      businessPlanService
        .deleteBusinessPlanSession(state.businessPlanSession.id)
        .then(result => {
          Toaster.showSuccess("Business plans deleted successfully")
          if (result) {
            dispatch({ type: ActionTypes.UPDATE_PARAMETER, parameter: "id", value: null })
            dispatch({ type: ActionTypes.SET_BUSINESS_PLANS, value: [] as MIDASBusinessPlanInput[] })
            dispatch({ type: ActionTypes.IS_UPLOAD_DISABLED, value: false })
            let revisionNumbers = [...state.possibleRevisionNumbers]
            const indexOfDeletedRevisionNumber = revisionNumbers.indexOf(state.possibleRevisionNumbers.filter(x => x.value === state.businessPlanSession.revisionNumber)[0])
            console.log(indexOfDeletedRevisionNumber)
            revisionNumbers.splice(indexOfDeletedRevisionNumber + 1)
            dispatch({ type: ActionTypes.SET_POSSIBLE_REVISON_NUMBERS, value: revisionNumbers })
          }
        })
        .catch((error) => { console.log(error) })
        .finally(() => dispatch({ type: ActionTypes.STOP_LOADING }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.deleteBusinessPlanSessionCount])

  return (
    <Page title="Upload deasonalized business plan data">
      <UploadDeseasonalizedBusinessPlanStateContext.Provider value={state}>
        <Loading isLoading={state.isLoading} />
        <UploadDeseasonalizedBusinessPlanDispatchContext.Provider value={dispatch}>
          <div className="card flex flex-wrap justify-content-center gap-3">
            <ConfirmDialog
              visible={state.showDeleteBusinessPlanSessionModal}
              onHide={() => dispatch({ type: ActionTypes.HIDE_DELETE_BUSINESS_PLAN_SESSION_MODAL })}
              message={state.showDeleteBusinessPlanSessionModal ? `Are you sure you want to delete existing ${convertElementTypeValueToString(state.businessPlanSession.elementType)} business plan data for ${state.businessPlanSession.year}, ${convertVersionNumberToString(state.businessPlanSession.revisionNumber)}` : ""}
              header="Delete existing business plan data?"
              icon="pi pi-exclamation-triangle"
              accept={() => {
                dispatch({ type: ActionTypes.DELETE_BUSINESS_PLAN_SESSION })
              }}
            />
            <Dialog header="Error" visible={!!errorMessage} style={{ width: '50vw' }} onHide={() => setErrorMessage("")}>
              <p className="m-0">
                {errorMessage}
              </p>
            </Dialog>
            <div className="UploadBusinessPlan">
              <UploadDeasonalizedBusinessPlanForm formikRef={formikRef} fileUploadRef={fileUploadRef} />
            </div>
            <div style={{ boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.3)", marginTop: "20px" }}>
              {!!state.businessPlanSession.businessPlans.length && (
                <>
                  {state.businessPlanSession.id && <Message severity="error" text={`${convertElementTypeValueToString(state.businessPlanSession.elementType)} business plan data already exists for ${state.businessPlanSession.year}, ${convertVersionNumberToString(state.businessPlanSession.revisionNumber)}`} style={{ width: "100%" }} />}
                  <BusinessPlanList />
                  {/* <BusinessPlans /> */}
                </>
              )}
            </div>
          </div>
        </UploadDeseasonalizedBusinessPlanDispatchContext.Provider>
      </UploadDeseasonalizedBusinessPlanStateContext.Provider>
    </Page>
  )
}

export default UploadDeasonalizedBusinessPlan
