import { APIService } from "./api.service"
import { SpendClassType, SpendClass } from "../../types/SpendClass"

export default class SpendClassService extends APIService {
  private url = "/api/spendclass"

  async getSpendClassTypesForElementType(elementType: Number): Promise<SpendClassType[]> {
    const response = await this.api.get(`${this.url}/types/${elementType}?includeInactive=true`)
    return response.data
  }

  async getSpendClassesForSpendClassType(businessEntityId, spendClassTypeId): Promise<SpendClass[]> {
    console.log(spendClassTypeId, businessEntityId)
    const response = await this.api.get(`${this.url}/${businessEntityId}/${spendClassTypeId}?includeInactive=true`)
    return response.data
  }
}
