import React from "react"
import "./AuthenticationLoading.scss"

const AuthenticationLoading: React.FC = () => (
  <div className="content align-items-center justify-content-center">
    <h1>authentication in progress...</h1>
  </div>
)

export default AuthenticationLoading
