import { FC, useEffect, useMemo, useRef, useCallback } from "react"

import { useImmerReducer } from "use-immer"

import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"
import { ConfirmDialog } from 'primereact/confirmdialog';
import "primereact/resources/themes/lara-light-indigo/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import 'primeflex/primeflex.css';

import Page from "../../../components/Page"
import ElementList from "../../../components/ElementList"
import AddOrEditElementForm from "../../../components/AddOrEditElementForm"

import { ManageElementsStateContext, ManageElementsDispatchContext } from "../../../state/Context"
import { initialManageElementsState, manageElementsReducer } from "../../../state/ManageElements"

import ActionType from "../../../state/ActionTypes"

import EntityService from "../../../services/api/entity.service"
import { Toaster } from "../../../services/toaster.service"

import { useLocation } from "react-router-dom";
import { Element } from "../../../types/Element";

import "./ManageElements.scss"

interface ManageElementsProps { }

const ManageElements: FC<ManageElementsProps> = props => {
  const [state, dispatch] = useImmerReducer(manageElementsReducer, initialManageElementsState)
  const formikRef = useRef<any>();

  const entityService = useMemo(() => new EntityService(), [])
  const location = useLocation()

  const loadData = useCallback(() => {
    dispatch({ type: ActionType.START_LOADING })
    dispatch({ type: ActionType.CLEAR_OBJECT_LIST })
    entityService.getEntitiesWithElements().then((data) => {
      dispatch({ type: ActionType.SET_OBJECT_LIST, value: data.items })
    }).catch(() => {
    }).finally(() => {
      dispatch({ type: ActionType.STOP_LOADING })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    if (location) {
      loadData()
    }
  }, [location, dispatch, entityService, loadData])


  useEffect(() => {
    if (state.loadCount) {
      loadData()
    }
  }, [state.loadCount, loadData])

  useEffect(() => {
    dispatch({ type: ActionType.CHANGE_ADD_OR_EDIT_MODAL_TITLE, value: state.isEdit === true ? "Edit element" : "Add element" })
  }, [state.isEdit, dispatch, state.elementToAddOrEdit])



  const addOrEditDialogFooterContent = (
    <div>
      <Button label="Cancel" icon="pi pi-times" disabled={state.isSaving === true} onClick={() => dispatch({ type: ActionType.TOGGLE_ADD_OR_EDIT_OBJECT_MODAL })} className="p-button-text" />
      <Button label="Submit" type="submit" style={{ background: "#0b2d71" }} disabled={!state.allowSubmit} loading={state.isSaving === true} onClick={() => {
        if (formikRef.current) {
          formikRef.current.requestSubmit()
        }
      }} icon="pi pi-check" autoFocus />
    </div>
  )

  return (
    <Page title="Manage Elements">
      <ManageElementsStateContext.Provider value={state}>
        <ManageElementsDispatchContext.Provider value={dispatch}>
          <div className="card flex flex-wrap justify-content-center gap-3">
            <div className="ManageElement">
              <Dialog header={state.addOrEditModalTitle} visible={state.showAddOrEditModal} style={{ width: "50vw" }} closable={!state.isSaving} onHide={() => dispatch({ type: ActionType.TOGGLE_ADD_OR_EDIT_OBJECT_MODAL })} footer={addOrEditDialogFooterContent}>
                {/* <AddOrEditElementForm formikRef={formikRef}></AddOrEditElementForm> */}
              </Dialog>
              <ConfirmDialog visible={state.showChangeElementStatusModal} onHide={() => dispatch({ type: ActionType.HIDE_CHANGE_STATUS_DIALOG })} message={`Are you sure you want to ${state.elementToChangeStatus.isActive ? "disable" : "enable"} this element?`}
                header="Change element status?" icon="pi pi-exclamation-triangle" accept={() => {
                  dispatch({ type: ActionType.INCREASE_CHANGE_STATUS_COUNT })
                }
                } />
              <>
                <div className="card">
                  <div className="card-body element-list">
                    <ElementList />
                  </div>
                </div>
              </>
            </div>
          </div>
        </ManageElementsDispatchContext.Provider>
      </ManageElementsStateContext.Provider>
    </Page>
  )
}

export default ManageElements
