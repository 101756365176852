import { Enums } from "../../types"
import { BusinessPanType, BusinessPlan } from "../../types/BusinessPlan"
import { BusinessPlanSession } from "../../types/BusinessPlanSession"
import { PagedList } from "../../types/PagedList"

import { APIService } from "./api.service"

export default class BusinessPlanService extends APIService {

  private url = "/api/businessPlans"

  async getRevisionNumberForLastLoadedBusinessPlanningSession(year: number, elementType: number, businessPlanType?: BusinessPanType): Promise<number> {
    const params = new URLSearchParams()
    if (businessPlanType) {
      params.append("businessPlanType", businessPlanType.toString())
    }

    const response = await this.api.get(`${this.url}/session/last/revision-number/${year}/${elementType}`, { params })
    return response.data
  }


  async getLastLoadedBusinessPlanningSession(year: number, elementType: number, revisionNumber: number, businessPlanType?: BusinessPanType, businessEntityId?: string, spendClassTypeId?: string): Promise<BusinessPlanSession> {
    const params = new URLSearchParams()
    if (revisionNumber) {
      params.append("revisionNumber", revisionNumber.toString())
    }

    if (businessPlanType) {
      params.append("businessPlanType", businessPlanType.toString())
    }

    if (businessEntityId) {
      params.append("businessEntityId", businessEntityId.toString())
    }

    if (spendClassTypeId) {
      params.append("spendClassTypeId", spendClassTypeId.toString())
    }

    const response = await this.api.get(`${this.url}/session/last/${year}/${elementType}`, { params })
    return response.data
  }

  async saveDeseasonalizedBusinessPlanSession(businessPlanSession: BusinessPlanSession): Promise<string> {
    var sessionToCreate = {
      year: businessPlanSession.year,
      elementType: businessPlanSession.elementType,
      revisionNumber: businessPlanSession.revisionNumber,
      businessPlans: businessPlanSession.businessPlans.map(x => ({
        businessEntityId: x.businessEntityId.value,
        value: x.value.value
      }))
    }

    const response = await this.api.post(`${this.url}/load/deseasonalized`, sessionToCreate)
    return response.data
  }

  async saveSeasonalizedBusinessPlanSession(businessEntityId: string, deseasonalizedBusinessPlanSesionId: string, businessPlanSession: BusinessPlanSession): Promise<string> {
    var sessionToCreate = {
      year: businessPlanSession.year,
      elementType: businessPlanSession.elementType,
      deseasonalizedBusinessPlanSesionId: deseasonalizedBusinessPlanSesionId,
      spendClassTypeId: businessPlanSession.spendClassType.id,
      businessPlans: businessPlanSession.businessPlans.filter(x => x.class.name.toLowerCase() !== "Total".toLowerCase()).map((businessPlan) => {
        const validKeys = Object.keys(businessPlan).filter(x => x.toLowerCase() !== "Class".toLowerCase() && x.toLowerCase() !== "Total".toLowerCase())
        return validKeys.map((key, index) => ({
          businessEntityId: businessEntityId,
          spencClassId: businessPlanSession.elementType === Enums.ElementType.CAPEX.value ? null : businessPlan.class.value,
          projectClassId: businessPlanSession.elementType === Enums.ElementType.CAPEX.value ? businessPlan.class.value : null,
          value: businessPlan[key].value,
          month: index + 1
        }))
      }
      ).flat(1)
    }

    console.log(sessionToCreate)

    const response = await this.api.post(`${this.url}/load/seasonalized`, sessionToCreate)
    return response.data
  }

  async deleteBusinessPlanSession(businessPlanSessionId: string): Promise<boolean> {
    const response = await this.api.delete(`${this.url}/session/${businessPlanSessionId}`)
    return response.data
  }

  async getLastLoadedDeseasonalizedBusinessPlanSession(businessEntityId: string, year: number, elementType: number, revisionNumber: number): Promise<BusinessPlanSession> {
    const response = await this.api.get(`${this.url}/session/unseasonalized/last/${businessEntityId}/${year}/${elementType}`)
    return response.data
  }



  async getBusinessPlans(year: number, elementType: number, revisionNumber: number): Promise<PagedList<BusinessPlan>> {
    const elementTypeObject = Enums.ElementTypes.find(x => x.value === elementType)
    const response = await this.api.get(`${this.url}/${elementTypeObject.subPath}/${year}/${revisionNumber}`)
    return response.data
  }

  async deleteExistingBusinessPlans(year: number, elementType: number, revisionNumber: number): Promise<PagedList<BusinessPlan>> {
    const elementTypeObject = Enums.ElementTypes.find(x => x.value === elementType)
    const response = await this.api.delete(`${this.url}/${elementTypeObject.subPath}/${year}/${revisionNumber}`)
    return response.data
  }

  async uploadBusinessPlan(year: number, elementType: number, revisionNumber: number, file: File): Promise<PagedList<BusinessPlan>> {
    let formData = new FormData()
    formData.append("year", year.toString())
    formData.append("revisionNumber", revisionNumber.toString())
    formData.append("file", file)

    const elementTypeObject = Enums.ElementTypes.find(x => x.value === elementType)
    const response = await this.api.post(`${this.url}/${elementTypeObject.subPath}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })

    return response.data
  }
}
