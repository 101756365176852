import { FC, useEffect, useMemo, useRef, useCallback } from "react"

import { useImmerReducer } from "use-immer"

import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"
import { ConfirmDialog } from "primereact/confirmdialog"
import "primereact/resources/themes/lara-light-indigo/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import "primeflex/primeflex.css"

import Page from "../../../components/Page"
import EntityList from "../../../components/EntityList"
import AddOrEditEntityForm from "../../../components/AddOrEditEntityForm"
import EntityOrganizationalChart from "../../../components/EntityOrganizationalChart"

import { ManageEntityStateContext, ManageEntityDispatchContext } from "../../../state/Context"
import { initialManageEntityState, manageEntityReducer } from "../../../state/ManageBusinessEntities"

import ActionType from "../../../state/ActionTypes"

import BusinessEntityService from "../../../services/api/businessEntity.service"
import { Toaster } from "../../../services/toaster.service"

import "./ManageBusinessEntities.scss"
import { useLocation } from "react-router-dom"

interface ManageBusinessEntitiesProps {}

const ManageBusinessEntities: FC<ManageBusinessEntitiesProps> = props => {
  const location = useLocation()

  const [state, dispatch] = useImmerReducer(manageEntityReducer, initialManageEntityState)
  const formikRef = useRef<any>()

  const businessEntityService = useMemo(() => new BusinessEntityService(), [])

  const loadEntities = useCallback(() => {
    dispatch({ type: ActionType.START_LOADING })
    dispatch({ type: ActionType.CLEAR_OBJECT_LIST })
    businessEntityService
      .getTopLevelBusinessEntities(state.includeActiveBusinessEntity, true)
      .then(data => {
        dispatch({ type: ActionType.SET_OBJECT_LIST, value: { totalCount: data.totalCount, businessEntities: data.items } })
      })
      .catch(() => {})
      .finally(() => {
        dispatch({ type: ActionType.STOP_LOADING })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.includeActiveBusinessEntity, location])

  useEffect(() => {
    if (location) {
      loadEntities()
    }
  }, [location, loadEntities])

  useEffect(() => {
    if (state.loadCount) {
      loadEntities()
    }
  }, [state.loadCount, loadEntities])

  useEffect(() => {
    dispatch({ type: ActionType.CHANGE_ADD_OR_EDIT_MODAL_TITLE, value: state.isEdit === true ? "Edit business entity" : "Add business entity" })
  }, [state.isEdit, dispatch, state.entityToAddOrEdit])

  useEffect(() => {
    if (state.saveCount) {
      dispatch({ type: ActionType.SET_SAVING, value: true })
      dispatch({ type: ActionType.START_LOADING })
      if (!state.isEdit) {
        businessEntityService
          .createEntity(state.entityToAddOrEdit)
          .then(entity => {
            if (entity.parentEntityId) {
              dispatch({ type: ActionType.ADD_CHILD_OBJECTS_TO_OBJECT, value: { parentEntityId: state.entityToAddOrEdit.parentEntityId, subEntities: [entity], subEntitesNewlyCreated: true } })
            } else {
              dispatch({ type: ActionType.ADD_NEW_OBJECT_TO_OBJECT_LIST, value: entity })
            }
            dispatch({ type: ActionType.TOGGLE_ADD_OR_EDIT_OBJECT_MODAL })
            Toaster.showSuccess("Business entity created successfully")
          })
          .catch(() => {})
          .finally(() => {
            dispatch({ type: ActionType.SET_SAVING, value: false })
            dispatch({ type: ActionType.STOP_LOADING })
          })
      } else {
        businessEntityService
          .editEntity(state.entityToAddOrEdit)
          .then(entity => {
            dispatch({ type: ActionType.UPDATE_EDITED_OBJECT, entity })
            dispatch({ type: ActionType.TOGGLE_ADD_OR_EDIT_OBJECT_MODAL })
            Toaster.showSuccess("Business entity updated successfully")
          })
          .catch(() => {})
          .finally(() => {
            dispatch({ type: ActionType.SET_SAVING, value: false })
            dispatch({ type: ActionType.STOP_LOADING })
          })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.saveCount])

  // useEffect(() => {
  //   if (state.changeStatusCount) {
  //     dispatch({ type: ActionType.START_LOADING })
  //     if (!isSubEntity(state.entityToChangeStatus)) {
  //       const entity = state.entityToChangeStatus as BusinessEntity
  //       businessEntityService.changeEntityStatus(entity.id, !entity.isActive).then((entity) => {
  //         dispatch({ type: ActionType.UPDATE_EDITED_OBJECT, entity })
  //       }).catch(() => {
  //       }).finally(() => {
  //         dispatch({ type: ActionType.STOP_LOADING })
  //       })
  //     }
  //     else {
  //       const subEntity = state.entityToChangeStatus as SubEntity
  //       businessEntityService.changeSubEntityStatus(subEntity.entityId, subEntity.id, !subEntity.isActive).then((subEntity) => {
  //         dispatch({ type: ActionType.UPDATE_EDITED_SUB_OBJECT, subEntity })
  //       }).catch(() => {
  //       }).finally(() => {
  //         dispatch({ type: ActionType.STOP_LOADING })
  //       })
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state.changeStatusCount])

  const addOrEditDialogFooterContent = (
    <div>
      <Button label="Cancel" icon="pi pi-times" disabled={state.isSaving === true} onClick={() => dispatch({ type: ActionType.TOGGLE_ADD_OR_EDIT_OBJECT_MODAL })} className="p-button-text" />
      <Button
        label="Submit"
        type="submit"
        style={{ background: "#0b2d71" }}
        disabled={!state.allowSubmit}
        loading={state.isSaving === true}
        onClick={() => {
          if (formikRef.current) {
            formikRef.current.requestSubmit()
          }
        }}
        icon="pi pi-check"
        autoFocus
      />
    </div>
  )

  return (
    <Page title="Manage business entities">
      <ManageEntityStateContext.Provider value={state}>
        <ManageEntityDispatchContext.Provider value={dispatch}>
          <div className="card flex flex-wrap justify-content-center gap-3">
            <div className="ManageEntity">
              <Dialog header={state.addOrEntityModalTitle} visible={state.showAddOrEditEntityModal} style={{ width: "50vw" }} closable={!state.isSaving} onHide={() => dispatch({ type: ActionType.TOGGLE_ADD_OR_EDIT_OBJECT_MODAL })} footer={addOrEditDialogFooterContent}>
                <AddOrEditEntityForm formikRef={formikRef}></AddOrEditEntityForm>
              </Dialog>
              <Dialog header={state.entityOrgChartTitle} visible={state.showEntityOrgChart} style={{ width: "50vw" }} onHide={() => dispatch({ type: ActionType.TOGGLE_OBJECT_ORG_CHART_MODAL })}>
                {state.entityToDisplayOrgChartFor.id && <EntityOrganizationalChart entity={state.entityToDisplayOrgChartFor} />}
              </Dialog>
              <ConfirmDialog
                visible={state.showChangeEntityStatusModal}
                onHide={() => dispatch({ type: ActionType.HIDE_CHANGE_STATUS_DIALOG })}
                message={`Are you sure you want to ${state.entityToChangeStatus.isActive ? "disable" : "enable"} this entity?`}
                header="Change entity status?"
                icon="pi pi-exclamation-triangle"
                accept={() => {
                  dispatch({ type: ActionType.INCREASE_CHANGE_STATUS_COUNT })
                }}
              />
              <>
                <div className="card">
                  <div className="card-body entity-list">
                    <EntityList />
                  </div>
                </div>
              </>
            </div>
          </div>
        </ManageEntityDispatchContext.Provider>
      </ManageEntityStateContext.Provider>
    </Page>
  )
}

export default ManageBusinessEntities
