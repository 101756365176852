import { useEffect, useMemo, useState } from "react"
import { Navigate, Routes, Route } from "react-router-dom"

import { useImmerReducer } from "use-immer"
import { AuthenticatedTemplate } from "@azure/msal-react"

import { getUserProfile, getUserProfileImage } from "../../auth"
import { UserProfile } from "../../types/Profile"
import AuthenticationLoading from "../../components/AuthenticationLoading"
import AppStateContext from "../../state/AppStateContext"
import AppDispatchContext from "../../state/AppDispatchContext"

import SessionService from "../../services/api/session.service"

import Header from "../../components/Header"
import HomePage from "../HomePage"
import NotFound from "../NotFound"
import ManageBusinessEntities from "../admin/ManageBusinessEntities"

import "./App.scss"
import LoadCandEDataAdminPage from "../admin/LoadCandEDataAdminPage"
import ManageElements from "../admin/ManageElements"
import UploadDeasonalizedBusinessPlan from "../admin/UploadDeasonalizedBusinessPlan"
import AllBusinessPlan from "../admin/AllBusinessPlan"
import UploadSeasonalizedBusinessPlan from "../admin/UploadSeasonalizedBusinessPlan"
import { error } from "console"

interface AppState {
  userProfile: UserProfile
}

function App() {
  const [loading, setLoading] = useState(true)
  const [loadProfileImageCount, setLoadProfileImageCount] = useState(0)
  const sessionService = useMemo(() => new SessionService(), [])

  const initialState: AppState = {
    userProfile: null
  }

  function stateReducer(draft, action) {
    switch (action.type) {
      case "setUserProfile":
        draft.userProfile = action.value
        return
      case "loadUserProfileImage":
        setLoadProfileImageCount(loadProfileImageCount + 1)
        return
      case "setUserProfileImage":
        draft.userProfile.image = action.value
        return
    }
  }

  const [state, dispatch] = useImmerReducer(stateReducer, initialState)

  useEffect(() => {
    setLoading(true)

    const tasks = []
    tasks.push(sessionService.getSession())
    tasks.push(getUserProfile())

    Promise.all(tasks)
      .then(result => {
        const userSession = result[0]
        const userProfile = result[1]
        if (userSession) {
          userProfile.businessEntitiesAssignedToUserRoles = userSession.businessEntitiesAssignedToUserRoles
          userProfile.roles = userSession.roles
          userProfile.userId = userSession.id

          dispatch({ type: "setUserProfile", value: userProfile })
          dispatch({ type: "loadUserProfileImage" })
        }
      })
      .catch(err => {
        console.log(err)
        return
      })
      .finally(() => setLoading(false))
  }, [dispatch, sessionService])

  useEffect(() => {
    if (loadProfileImageCount > 0) {
      getUserProfileImage(state.userProfile.oId)
        .then(x => {
          dispatch({ type: "setUserProfileImage", value: x })
          return
        })
        .catch(() => {
          return
        })
    }
  }, [loadProfileImageCount, dispatch, state.userProfile?.oId])

  return (
    <div className="app">
      {loading && <AuthenticationLoading />}
      {!loading && (
        <AuthenticatedTemplate>
          <AppStateContext.Provider value={state}>
            <AppDispatchContext.Provider value={dispatch}>
              <Header />
              <main className="content ">
                {loading && <AuthenticationLoading />}
                {!loading && (
                  <Routes>
                    <Route path="/admin/business-entities" element={<ManageBusinessEntities />} />
                    <Route path="/admin/elements" element={<ManageElements />} />
                    <Route path="/admin/capital-and-exploratory" element={<LoadCandEDataAdminPage />} />
                    <Route path="/admin/businessplan/load/deseasonalized" element={<UploadDeasonalizedBusinessPlan />} />
                    <Route path="/admin/businesspan/load/seasonalized" element={<UploadSeasonalizedBusinessPlan />} />
                    <Route path="/admin/businesspan/search/deseasonalized" element={<AllBusinessPlan />} />
                    <Route path="/" element={<Navigate replace to="/admin/business-entities" />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                )}
              </main>
            </AppDispatchContext.Provider>
          </AppStateContext.Provider>
        </AuthenticatedTemplate>
      )}
    </div>
  )
}

export default App
