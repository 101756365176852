import React, { useState, useEffect, useContext } from "react"

import AppStateContext from "../../state/AppStateContext"
import "./ProfileImage.scss"

interface ProfileImageProps {
  firstName: string
  lastName: string
  oId: string
}

const ProfileImage: React.FC<ProfileImageProps> = (props: ProfileImageProps) => {
  const [initials, setInitials] = useState<string>("")
  const appState = useContext(AppStateContext)

  useEffect(() => {
    let initial = ""

    if (props.firstName && props.firstName.length >= 1) {
      initial = props.firstName.slice(0, 1)
      if (props.lastName && props.lastName.length >= 1) initial = initial + props.lastName.slice(0, 1)
    }

    setInitials(initial)
  }, [props.oId, props.firstName, props.lastName])

  return <>{appState.userProfile.image ? <img className="user-image rounded-circle" src={appState.userProfile.image} alt="user-pic" /> : <div className="user-initials">{initials}</div>}</>
}

export default ProfileImage
