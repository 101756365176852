import { BusinessEntity, SubEntity } from "../../types/BusinessEntity"
import { PagedList } from "../../types/PagedList"
import { APIService } from "./api.service"

export default class EntityService extends APIService {
    private url = "/api/v1/entities"

    async getEntities(): Promise<PagedList<BusinessEntity>> {
        const response = await this.api.get(`${this.url}`)
        return response.data
    }

    async getEntitiesWithElements(): Promise<PagedList<BusinessEntity>> {
        const params = new URLSearchParams();
        params.append("withElements", true.toString())
        const response = await this.api.get(`${this.url}`, { params })
        return response.data
    }

    async createEntity(entity: BusinessEntity): Promise<BusinessEntity> {
        const response = await this.api.post(`${this.url}`, entity)
        return response.data
    }

    async createSubEntity(subEntity: SubEntity): Promise<SubEntity> {
        let copiedSubEntity = { ...subEntity }
        delete copiedSubEntity.entityId
        const response = await this.api.post(`${this.url}/${subEntity.entityId}/sub-entity`, copiedSubEntity)
        return response.data
    }

    async editEntity(entity: BusinessEntity): Promise<BusinessEntity> {
        const response = await this.api.put(`${this.url}`, entity)
        return response.data
    }

    async editSubEntity(subEntity: SubEntity): Promise<SubEntity> {
        let copiedSubEntity = { ...subEntity }
        delete copiedSubEntity.entityId
        const response = await this.api.put(`${this.url}/${subEntity.entityId}/sub-entity`, copiedSubEntity)
        return response.data
    }

    async changeEntityStatus(entityId: string, newStatus: boolean): Promise<BusinessEntity> {
        const response = await this.api.put(`${this.url}/status`, { id: entityId, NewStatusIsActive: newStatus })
        return response.data
    }

    async changeSubEntityStatus(entityId: string, subEntityId: string, newStatus: boolean): Promise<BusinessEntity> {
        const response = await this.api.put(`${this.url}/${entityId}/sub-entity-status`, { id: subEntityId, NewStatusIsActive: newStatus })
        return response.data
    }
}
