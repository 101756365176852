import { useCallback } from "react"
import { BusinessPlan, BusinessPlanValueInput, MIDASBusinessPlanInput } from "../types/BusinessPlan"

export default function useConvertRegularBusinessPlanToMIDASBusinessPlanInput() {
    const convertRegularBusinessPlanToMIDASBusinessPlanInput = useCallback((businessPlan: BusinessPlan): MIDASBusinessPlanInput => {
        return {
            accountId: {
                error: null,
                value: null
            },
            businessEntityId: {
                error: null,
                value: businessPlan.businessEntityId
            },
            businessEntityBusinessPlanDataSourceId: {
                error: null,
                value: businessPlan.businessEntityBusinessPlanDataSourceId
            },
            businessEntityName: {
                error: null,
                value: businessPlan.businessEntityName
            },
            value: {
                error: null,
                value: businessPlan.value
            } as BusinessPlanValueInput
        }
    }, [])

    return convertRegularBusinessPlanToMIDASBusinessPlanInput
}
