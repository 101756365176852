import { SilentRequest } from "@azure/msal-browser"
import jwtDecode from "jwt-decode"
import { msalInstance } from "../index"
import { apiScope } from "../auth-config"
import { UserProfile } from "../types/Profile"
import { Toaster } from "../services/toaster.service"
import MS_Graph_Service from "../services/ms-graph-service"

const ms_graph_service = new MS_Graph_Service()

/// <summary>
/// get User Token from azure and convert it to UserPrfofile model
/// </summary>
/// <param name=""></param>
/// <returns></returns>
export const getUserProfile = async (): Promise<UserProfile> => {
  const activeAccount = msalInstance.getActiveAccount()
  const accounts = msalInstance.getAllAccounts()

  const tokenRequest: SilentRequest = {
    scopes: apiScope.scopes,
    account: activeAccount || accounts[0]
  }

  return msalInstance
    .acquireTokenSilent(tokenRequest as SilentRequest)
    .catch((err: any) => {
      Toaster.showError("acquireToken request failed")
      throw err
    })
    .then(graphToken => jwtDecode(graphToken.accessToken))
    .catch((err: any) => {
      Toaster.showError("jwtDecode failed")
      throw err
    })
    .then(decodedToken => {
      const userProfile: UserProfile = {
        firstName: decodedToken["given_name"],
        lastName: decodedToken["family_name"],
        email: decodedToken["upn"],
        oId: decodedToken["oid"],
        userId: null,
        image: null,
        roles: null,
        businessEntitiesAssignedToUserRoles: null
      }
      return userProfile
    })
    .catch((err: any) => {
      Toaster.showError("error in getUserProfile()")
      throw err
    })
}

export const getUserProfileImage = async (oId: string): Promise<string> => {
  const pImageData = await ms_graph_service.getUserProfileImage(oId)
  return URL.createObjectURL(pImageData)
}
