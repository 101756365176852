import { FC, useEffect, useState, useContext } from 'react'

import { TreeTable } from 'primereact/treetable'
import { Column } from 'primereact/column'
import { TreeNode } from 'primereact/treenode'
import { Tag } from 'primereact/tag'

import { ManageElementsStateContext, ManageElementsDispatchContext } from '../../state/Context';
import useConvertEntityToPrimeReactTreeNode from "../../hooks/useConvertEntityToPrimeReactTreeNode"

import { Button } from 'primereact/button'
import ActionTypes from '../../state/ActionTypes'
import './ElementList.scss';
import { isEntity } from '../../helpers/typeHelpers'

interface ElementListProps {
}

const ElementList: FC<ElementListProps> = (props) => {
  const manageElementsDispatch = useContext(ManageElementsDispatchContext)
  const manageElementsState = useContext(ManageElementsStateContext)

  const [elementsAsNodes, setElementsAsNodes] = useState<TreeNode[]>([])
  const convertEntityToNode = useConvertEntityToPrimeReactTreeNode()

  useEffect(() => {
    let nodes = [] as TreeNode[]
    setElementsAsNodes(null)
    manageElementsState.entities.forEach(entity => {
      nodes.push(convertEntityToNode(entity, true))
    })
    setElementsAsNodes(nodes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageElementsState.entities])



  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">Elements</span>
      <div>
        {/* <Button type="button" icon="pi pi-plus" label="Expand All" className='mr-2' onClick={expandAll} />
        <Button type="button" icon="pi pi-minus" label="Collapse All" className='mr-2' onClick={collapseAll} /> */}
        <Button icon="pi pi-refresh" tooltip="refresh" severity="warning"
          tooltipOptions={{ position: "left" }} className='mr-2' onClick={() => {
            manageElementsDispatch({ type: ActionTypes.RELOAD_OBJECTS })
          }} rounded raised />
        <Button
          tooltip="add element"
          tooltipOptions={{ position: "left" }}
          onClick={() => {
            manageElementsDispatch({ type: ActionTypes.RESET_ADD_OR_EDIT_OBJECT, isSubElement: false })
            manageElementsDispatch({ type: ActionTypes.CHANGE_ADD_OR_EDIT_OBJECT_EDIT_MODE, value: false })
            manageElementsDispatch({ type: ActionTypes.TOGGLE_ADD_OR_EDIT_OBJECT_MODAL })
          }}
          icon="pi pi-plus"
          severity="info"
          rounded
          raised
        // className="addButton text-right"
        />
      </div>
    </div>
  );


  const statusTagTemplate = (node, column) => {
    if ("isActive" in node.data) {
      return <Tag value={node.data["isActive"] ? "Active" : "Inactive"} severity={node.data["isActive"] ? "success" : "warning"}></Tag>;
    }
  }

  const listButtonStyle = {
    height: "2rem",
    width: "2rem"
  }

  const actionTemplate = (node) => {
    return (
      <div className="actions flex flex-wrap gap-2 float-end">
        {
          isEntity(node.data) && node.data.isActive &&
          <>
            <Button type="button" className='mr-2' icon="pi pi-plus" severity="info" style={listButtonStyle} tooltip="Add sub element" tooltipOptions={{ position: 'left' }} rounded onClick={() => {
              manageElementsDispatch({ type: ActionTypes.RESET_ADD_OR_EDIT_OBJECT, isSubElement: true })
              manageElementsDispatch({ type: ActionTypes.SET_PARENT_ID_OF_ADD_OR_EDIT_OBJECT, parentId: node.data.elementId })
              manageElementsDispatch({ type: ActionTypes.CHANGE_ADD_OR_EDIT_OBJECT_EDIT_MODE, value: false })
              manageElementsDispatch({ type: ActionTypes.TOGGLE_ADD_OR_EDIT_OBJECT_MODAL })
            }}></Button>
          </>
        }
        {
          !isEntity(node.data) && node.data.isActive &&
          <>
            <Button type="button" className='mr-2' icon="pi pi-pencil" severity='secondary' style={{ ...listButtonStyle, fontSize: "0.5rem !important" }} tooltip="Edit" tooltipOptions={{ position: 'left' }} onClick={() => {
              manageElementsDispatch({ type: ActionTypes.RESET_ADD_OR_EDIT_OBJECT })
              manageElementsDispatch({ type: ActionTypes.CHANGE_ADD_OR_EDIT_OBJECT_VALUES, value: node.data })
              manageElementsDispatch({ type: ActionTypes.SET_PARENT_ID_OF_ADD_OR_EDIT_OBJECT, parentId: node.data.elementId })
              manageElementsDispatch({ type: ActionTypes.CHANGE_ADD_OR_EDIT_OBJECT_EDIT_MODE, value: true })
              manageElementsDispatch({ type: ActionTypes.TOGGLE_ADD_OR_EDIT_OBJECT_MODAL })
            }}
              rounded></Button>
            <Button
              type="button"
              icon={node.data.isActive ? "pi pi-times" : "pi pi-check"}
              style={listButtonStyle}
              tooltip={node.data.isActive ? "Deactivate" : "Activate"}
              tooltipOptions={{ position: 'left' }}
              severity={node.data.isActive ? "danger" : "success"}
              rounded
              onClick={() => {
                manageElementsDispatch({ type: ActionTypes.SET_OBJECT_TO_CHANGE_STATUS, element: node.data })
                manageElementsDispatch({ type: ActionTypes.SHOW_CHANGE_STATUS_DIALOG })
              }}
            ></Button>
          </>
        }
      </div >
    )
  }

  return (
    <div className="ElementList mt-6" >
      <TreeTable header={header} loading={manageElementsState.isLoading} rows={10} paginator rowsPerPageOptions={[10, 25, 50, 100]} value={elementsAsNodes}
        tableStyle={{ minWidth: "50rem" }}>
        <Column field="name" header="Name" expander sortable></Column>
        <Column field="description" header="Description" sortable></Column>
        <Column field="isActive" header="Status" body={statusTagTemplate}></Column>
        <Column body={actionTemplate} />
      </TreeTable>
    </div >
  );
}
export default ElementList;
