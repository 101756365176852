import React from "react"
import "./Error.scss"

const Error: React.FC = () => (
  <div className="content align-items-center justify-content-center">
    <h1>An error occurred</h1>
  </div>
)

export default Error
