import { FC, useContext, useRef } from "react"

import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { LoadCandEAdminStateContext, LoadCandEAdminDispatchContext } from "../../state/LoadCandEAdminContext"

import "./CandEStagingDataList.scss"
import { Button } from "primereact/button"

interface CandEStagingDataListProps {}

const CandEStagingDataList: FC<CandEStagingDataListProps> = () => {
  const loadCandEAdminState = useContext(LoadCandEAdminStateContext)
  const loadCandEAdminDispatch = useContext(LoadCandEAdminDispatchContext)
  const dataTableRef = useRef(null)

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" text onClick={() => loadCandEAdminDispatch({ type: "increaseLoadCount" })} />
  const paginatorRight = <Button type="button" icon="pi pi-download" text onClick={() => dataTableRef.current.exportCSV()} />

  const amountBodyTemplate = rowData => {
    return <span>{rowData.amount.toLocaleString("en-US")}</span>
  }

  return (
    <div className="CandEStagingDataList">
      <DataTable ref={dataTableRef} paginator rows={10} rowsPerPageOptions={[25, 50, 75, 100]} value={loadCandEAdminState.data} tableStyle={{ minWidth: "50rem" }} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}>
        <Column field="entityName" header="Entity" filter filterPlaceholder="Filter by entity name"></Column>
        <Column field="subEntityName" header="Sub entity" filter filterPlaceholder="Filter by sub entity name"></Column>
        <Column field="projectId" header="Project name" filter filterPlaceholder="Filter by project name"></Column>
        <Column field="amount" header="Actuals" body={amountBodyTemplate}></Column>
        <Column field="currency" header="Currency"></Column>
      </DataTable>
    </div>
  )
}

export default CandEStagingDataList
