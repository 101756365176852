import { UserProfile } from "../../types/Profile"
import { APIService } from "./api.service"

export default class SessionService extends APIService {
  private url = "/api/users"

  async getSession(): Promise<UserProfile> {
    const response = await this.api.get(`${this.url}/current-user`)
    return response.data
  }
}
