import { useCallback } from "react"
import { flattenList } from "../helpers";

export default function useFlattenArray() {
    const flattenArray = useCallback((arrayToFlatten: Array<any>, childrenPropertyName: string = "children"): Array<any> => {
        return flattenList(arrayToFlatten, childrenPropertyName)
    }, [])

    return flattenArray
}
