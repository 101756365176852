import { useCallback } from "react"
import { Enums } from "../types"

export default function useConvertElementTypeValueToString() {
    const convertElementTypeValueToString = useCallback((elementTypeValue: number): string => {
        const elementType = Enums.ElementTypes.find(x => x.value === elementTypeValue)
        return elementType ? elementType.description : null
    }, [])

    return convertElementTypeValueToString
}
