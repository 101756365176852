// More details here https://www.primefaces.org/primereact/toast

import { Toast } from "primereact/toast"
import CustomToast from "../components/CustomToast"

class ToasterService {
  private _instance: React.MutableRefObject<Toast>

  public get instance(): React.MutableRefObject<Toast> {
    return this._instance
  }

  init(ref: React.MutableRefObject<Toast>): void {
    this._instance = ref
  }

  showSuccess(message: string) {
    this.instance.current.show({
      severity: "success",
      content: CustomToast({ heading: "Success", message }),
      life: 3000
    })
  }

  showInfo(message: string) {
    this.instance.current.show({
      severity: "info",
      content: CustomToast({ heading: "Info", message }),
      life: 3000
    })
  }

  showWarn(message: string) {
    this.instance.current.show({
      severity: "warn",
      content: CustomToast({ heading: "Warn", message }),
      life: 3000
    })
  }

  showError(message: string) {
    this.instance.current.show({
      severity: "error",
      content: CustomToast({ heading: "Error", message }),
      sticky: true
    })
  }

  clearAllToastMessages() {
    this.instance.current.clear()
  }

  showErrorMultiple(messages: string[]) {
    const toastObjList = []

    messages.forEach(message =>
      toastObjList.push({
        severity: "error",
        content: CustomToast({ heading: "Error", message }),
        sticky: true
      })
    )

    this.instance.current.show(toastObjList)
  }
}

const Toaster = new ToasterService()

export { Toaster }
