export interface BusinessPlan {
    id: string
    value: number
    month?: number
    businessEntityId: string
    businessEntityName: string
    businessEntityBusinessPlanDataSourceId: string
    spendClassId?: string
    spendClassName?: string
    projectClassId?: string
    projectClassName?: string
}


export interface BusinessPlanValueInput {
    error: string,
    value: number
}

export interface MIDASBusinessPlanInput {
    id?: string,
    accountId: {
        error: string
        value: string
    },
    businessEntityId: {
        error: string
        value: string
    },
    businessEntityBusinessPlanDataSourceId: {
        error: string
        value: string
    },
    businessEntityName: {
        error: string
        value: string
    },
    value: BusinessPlanValueInput
}

export interface SeasonalizedBusinessPlanInput {
    class: {
        error: string
        name: string
        value: string
    },
    jan: BusinessPlanValueInput,
    feb: BusinessPlanValueInput,
    mar: BusinessPlanValueInput,
    apr: BusinessPlanValueInput,
    may: BusinessPlanValueInput,
    jun: BusinessPlanValueInput,
    jul: BusinessPlanValueInput,
    aug: BusinessPlanValueInput,
    sep: BusinessPlanValueInput,
    oct: BusinessPlanValueInput,
    nov: BusinessPlanValueInput,
    dec: BusinessPlanValueInput,
    total: BusinessPlanValueInput
}

export enum BusinessPanType {
    normal = 1,
    seasoanlized = 10
}