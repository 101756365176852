import { FC, useEffect, useMemo, useRef, useState } from "react"

import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

import "./BusinessPlans.scss"
import { Enums } from "../../types"
import { Dropdown } from "primereact/dropdown"
import { Divider } from "primereact/divider"
import { Button } from "primereact/button"
import { ElementType } from "../../types/Element"
import { Tooltip } from "primereact/tooltip"
import SeasionalizedService from "../../services/api/seasionalise.service"
import { BusinessPlanSession } from "../../types/BusinessPlanSession"

interface BusinessPlansProps {}

const BusinessPlans: FC<BusinessPlansProps> = props => {
  const currentYear = new Date().getFullYear()
  const [selectedYear, setSelectedYear] = useState<number>()
  const [selectedType, setSelectedType] = useState<ElementType | null>(null)
  const [typeName, setTypeName] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)

  const [seasonalizedBusinessPlan, setSeasonalizedBusinessPlan] = useState<BusinessPlanSession | null>(null)

  const years = Array.from({ length: currentYear - 2019 }, (_, index) => 2020 + index)
  const seasionalizedService = useMemo(() => new SeasionalizedService(), [])
  const dt = useRef(null)
  const exportCSV = selectionOnly => {
    dt.current.exportCSV({ selectionOnly })
  }
  const exportExcel = () => {
    import("xlsx").then(xlsx => {
      if (seasonalizedBusinessPlan?.businessPlans) {
        const worksheet = xlsx.utils.json_to_sheet(seasonalizedBusinessPlan?.businessPlans.map(item => ({ "Business Entity Name": item.businessEntityName, Value: item.value })))
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] }
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array"
        })

        saveAsExcelFile(excelBuffer, "businessplans")
      }
    })
  }

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then(module => {
      if (module && module.default) {
        let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
        let EXCEL_EXTENSION = ".xlsx"
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        })

        module.default.saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION)
      }
    })
  }
  useEffect(() => {
    const typeName = Enums.ElementTypes.find(a => a.value === Number(selectedType))
    setTypeName(typeName?.description)

    if (selectedYear && selectedType) {
      setLoading(true)
      seasionalizedService
        .getUnSeasionalizedData(selectedYear, Number(selectedType), null)
        .then(result => {
          setSeasonalizedBusinessPlan(result)
        })
        .catch(error => {})
        .finally(() => {
          setLoading(false)
        })
    }
  }, [selectedYear, selectedType, seasionalizedService])
  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">{typeName}</span>
      <div className="flex align-items-center justify-content-end gap-2">
        <Button type="button" label="CSV Export" onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
        <Button type="button" label="Excel Export" className="p-button-success" onClick={exportExcel} data-pr-tooltip="XLS" />
      </div>
    </div>
  )
  return (
    <div>
      <div className="row g-3">
        <div className="p-fluid col-md-6">
          <div className="p-field p-float-label mt-4 ml-4 mb-2">
            <Dropdown id="year" name="year" value={selectedYear} onChange={e => setSelectedYear(e.value)} options={years} placeholder="Select year" autoFocus className="w-full" />
            <label htmlFor="year">
              Year<span className="text-danger font-weight-bold text-large ">&nbsp;*</span>
            </label>
          </div>
        </div>

        <div className="p-fluid col-md-6">
          <div className="p-field p-float-label mb-2 mt-4">
            <Dropdown id="elementType" name="elementType" value={selectedType} onChange={e => setSelectedType(e.value)} options={Enums.ElementTypes} optionLabel="description" placeholder="Select type of plan to upload" className="w-full" />
            <label htmlFor="elementType">
              Type<span className="text-danger font-weight-bold text-large">&nbsp;*</span>
            </label>
          </div>
        </div>
      </div>
      <Divider />
      <div className="ElementList">
        <Tooltip target=".export-buttons>button" position="bottom" />
        <DataTable ref={dt} value={seasonalizedBusinessPlan?.businessPlans} loading={loading} header={header} rows={10} paginator rowsPerPageOptions={[10, 25, 50, 100]} tableStyle={{ minWidth: "50rem" }}>
          <Column field="businessEntityName" header="Business Entity Name" sortable></Column>
          <Column field="value" header="Value"></Column>
        </DataTable>
      </div>
    </div>
  )
}
export default BusinessPlans
