import { FC, useContext } from "react"

import { Divider } from "primereact/divider"
import { Dropdown } from "primereact/dropdown"
import { Calendar } from "primereact/calendar"

import { Enums } from "../../types"

import { LoadCandEAdminStateContext, LoadCandEAdminDispatchContext } from "../../state/LoadCandEAdminContext"

import "./LoadCandEDataForm.scss"
import { Button } from "primereact/button"

interface LoadCandEDataFormProps {
  // month: string
  // dataSource: number
  // isProcessing: boolean
}

const LoadCandEDataForm: FC<LoadCandEDataFormProps> = props => {
  const loadCandEAdminState = useContext(LoadCandEAdminStateContext)
  const loadCandEAdminDispatch = useContext(LoadCandEAdminDispatchContext)

  const maxDate = new Date()
  maxDate.setMonth(maxDate.getMonth() - 1)

  return (
    <div className="LoadCandEDataForm">
      <div className="flex justify-content-center">
        <span className="p-float-label">
          <Calendar
            maxDate={maxDate}
            name="monthcalendar"
            value={loadCandEAdminState.month}
            onChange={e => {
              loadCandEAdminDispatch({ type: "setMonth", value: e.target.value })
            }}
            view="month"
            dateFormat="mm/yy"
          />
          <label htmlFor="monthcalendar">Select month</label>
        </span>

        <Button className="" label="Process" style={{ background: "#0b2d71", marginLeft: "10px" }} disabled={!loadCandEAdminState.month || loadCandEAdminState.isLoading || loadCandEAdminState.hadErrorLoadingData} loading={loadCandEAdminState.isProcessing} onClick={() => loadCandEAdminDispatch({ type: "process" })} icon="pi pi-check" autoFocus />
      </div>
      <div className="p-field">
        <div className="flex justify-content-left"></div>
      </div>
    </div>
  )
}

export default LoadCandEDataForm
