import { useContext, useState, useEffect } from "react"

import slugify from "slugify"

import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Tooltip } from "primereact/tooltip"
import { Button } from "primereact/button"

import { UploadSeasonalizedBusinessPlanStateContext, UploadSeasonalizedBusinessPlanDispatchContext } from "../../state/Context"

import useConvertElementTypeValueToString from "../../hooks/useConvertElementTypeValueToString"
import { Enums } from "../../types"
import ActionTypes from "../../state/ActionTypes"
import { SeasonalizedBusinessPlanInput } from "../../types/BusinessPlan"

const SeasonalizedBusinessPlanList = () => {

  const uploadSeasonalizedBusinessPlanState = useContext(UploadSeasonalizedBusinessPlanStateContext)
  const uploadSeasonalizedBusinessPlanDispatch = useContext(UploadSeasonalizedBusinessPlanDispatchContext)

  const [entityHeader, setEntityHeader] = useState("")

  const convertElementTypeValueToString = useConvertElementTypeValueToString()

  useEffect(() => {
    let header = uploadSeasonalizedBusinessPlanState.businessPlanSession.elementType === Enums.ElementType.CAPEX.value ? "Project" : "Class"
    setEntityHeader(header)
  }, [uploadSeasonalizedBusinessPlanState.businessPlanSession.elementType])

  function regularBodyTemplate(businessPlan, column): JSX.Element {
    const businessPlanClassName = slugify(businessPlan.class.name, { remove: /[*+~.()'"!:@]/g })
    if (businessPlan.class.name.toLowerCase() === "Total".toLowerCase()) {
      const displayValue = column.field === "class" ? businessPlan[column.field].name : "$" + businessPlan[column.field].value.toLocaleString(undefined, { minimumFractionDigits: 5 })
      const colorStyle = column.field === "class" || !businessPlan[column.field].error ? "" : "text-danger"
      if (businessPlan[column.field].error) {
        return (
          <>
            <Tooltip target={`#${businessPlanClassName}_${column.field}`} position="right" />
            <span id={`${businessPlanClassName}_${column.field}`} className={`font-weight-bold ${colorStyle}`} data-pr-tooltip={businessPlan[column.field].error}>
              {displayValue}
            </span>
          </>
        )
      }
      return <span className={`font-weight-bold ${colorStyle}`}>{displayValue}</span>
    } else if (column.field.toLowerCase() === "Class".toLowerCase() && businessPlan[column.field].error) {
      return (
        <>
          <Tooltip target={`#${businessPlanClassName}`} position="right" />
          <span id={businessPlanClassName} className={`font-weight-bold text-danger`} data-pr-tooltip={businessPlan[column.field].error}>
            {businessPlan.class.name}
          </span>
        </>
      )
    }
    return <span className={businessPlan.class.name.toLowerCase() === "Total" ? "font-weight-bold" : ""}>{column.field === "class" ? businessPlan[column.field].name : "$" + businessPlan[column.field].value.toLocaleString(undefined, { minimumFractionDigits: 5 })}</span>
  }

  function totalBodyTemplate(businessPlan, column): JSX.Element {
    const colorStyle = column.field === "class" || !businessPlan[column.field].error ? "" : "text-danger"
    const businessPlanClassName = slugify(businessPlan.class.name, { remove: /[*+~.()'"!:@]/g })
    if (businessPlan[column.field].error) {
      return (
        <>
          <Tooltip target={`#${businessPlanClassName}_${column.field}`} position="left" />
          <span id={`${businessPlanClassName}_${column.field}`} className={`font-weight-bold ${colorStyle}`} data-pr-tooltip={businessPlan[column.field].error}>
            ${businessPlan[column.field].value.toLocaleString(undefined, { minimumFractionDigits: 5 })}
          </span>
        </>
      )
    }
    return <span className="font-weight-bold">${businessPlan[column.field].value.toLocaleString(undefined, { minimumFractionDigits: 5 })}</span>
  }
  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">{uploadSeasonalizedBusinessPlanState.businessPlanSession.spendClassType ? `${uploadSeasonalizedBusinessPlanState.businessPlanSession.year} Seasonalized business plan - ${convertElementTypeValueToString(uploadSeasonalizedBusinessPlanState.businessPlanSession.elementType)}` : "Seasonalized business plan"}</span>
      <div className="flex align-items-center justify-content-end">
        {uploadSeasonalizedBusinessPlanState.businessPlanSession.id && (
          <Button icon="pi pi-trash"
            severity="danger"
            onClick={() => {
              uploadSeasonalizedBusinessPlanDispatch({ type: ActionTypes.SHOW_DELETE_BUSINESS_PLAN_SESSION_MODAL })
            }} />
        )}

        {uploadSeasonalizedBusinessPlanState.enableFileSelect && (
          <>
            <Button className="ml-4 mr-4 p-danger"
              icon="pi pi-times"
              severity="danger"
              label="Cancel Upload"
              onClick={() => {
                uploadSeasonalizedBusinessPlanDispatch({ type: ActionTypes.SET_BUSINESS_PLANS, value: [] as SeasonalizedBusinessPlanInput[] })
              }}
            />
            <Button className="ml-4 mr-4"
              style={{ boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.3)" }}
              icon="pi pi-upload"
              label="Complete Upload"
              disabled={!uploadSeasonalizedBusinessPlanState.enableFileUpload}
              onClick={() => {
                uploadSeasonalizedBusinessPlanDispatch({ type: ActionTypes.UPLOAD_FILE })
              }}
            />
          </>
        )}
      </div>
    </div>
  )

  return (
    <DataTable header={header} value={uploadSeasonalizedBusinessPlanState.businessPlanSession.businessPlans} editMode="cell" tableStyle={{ minWidth: "50rem" }}>
      <Column field="class" header={entityHeader} body={regularBodyTemplate} />
      <Column field="jan" header="January" body={regularBodyTemplate} />
      <Column field="feb" header="February" body={regularBodyTemplate} />
      <Column field="mar" header="March" body={regularBodyTemplate} />
      <Column field="apr" header="April" body={regularBodyTemplate} />
      <Column field="may" header="May" body={regularBodyTemplate} />
      <Column field="jun" header="June" body={regularBodyTemplate} />
      <Column field="jul" header="July" body={regularBodyTemplate} />
      <Column field="aug" header="August" body={regularBodyTemplate} />
      <Column field="sep" header="September" body={regularBodyTemplate} />
      <Column field="oct" header="October" body={regularBodyTemplate} />
      <Column field="nov" header="November" body={regularBodyTemplate} />
      <Column field="dec" header="December" body={regularBodyTemplate} />
      <Column field="total" header="Total" body={totalBodyTemplate} />
    </DataTable>
  )
}

export default SeasonalizedBusinessPlanList
