import jsonpatch from "fast-json-patch"

import { BusinessEntity, SubEntity } from "../../types"
import { APIService } from "./api.service"
import { PagedList } from "../../types/PagedList"

export default class BusinessEntityService extends APIService {
  private url = "/api/businessEntities"

  async getAllBusinessEntities(includeInactive: boolean, sortColumn: string | null = null, sortOrder: number | null = null, page: number | null = null, pageSize: number | null = null): Promise<PagedList<BusinessEntity>> {
    const params = new URLSearchParams()
    if (sortColumn && sortOrder) {
      params.append("sortColumn", sortColumn)
      params.append("sortOrder", sortOrder === 1 ? "asc" : "desc")
    }
    if (page && pageSize) {
      params.append("page", page.toString())
      params.append("pageSize", pageSize.toString())
    }

    params.append("includeInactive", includeInactive.toString())

    const response = await this.api.get(this.url, {
      params
    })

    return response.data
  }

  async getTopLevelBusinessEntities(includeInactive: boolean, includeDescendants: boolean, sortColumn: string | null = null, sortOrder: number | null = null, page: number | null = null, pageSize: number | null = null): Promise<PagedList<BusinessEntity>> {
    const params = new URLSearchParams()
    if (sortColumn && sortOrder) {
      params.append("sortColumn", sortColumn)
      params.append("sortOrder", sortOrder === 1 ? "asc" : "desc")
    }
    if (page && pageSize) {
      params.append("page", page.toString())
      params.append("pageSize", pageSize.toString())
    }

    params.append("includeInactive", includeInactive.toString())
    params.append("includeDescendants", includeDescendants.toString())

    const response = await this.api.get(`${this.url}/top-level`, {
      params
    })
    return response.data
  }

  async getProjectTreeMembers(businessEntityId: string, includeInactive: boolean): Promise<PagedList<BusinessEntity>> {
    const params = new URLSearchParams()

    params.append("businessEntityId", businessEntityId)
    params.append("includeInactive", includeInactive.toString())

    const response = await this.api.get(`${this.url}/tree/projects`, {
      params
    })
    return response.data
  }

  async createEntity(entity: BusinessEntity): Promise<BusinessEntity> {
    const response = await this.api.post(`${this.url}`, entity)
    return response.data
  }

  async editEntity(entity: BusinessEntity): Promise<BusinessEntity> {
    const response = await this.api.put(`${this.url}`, entity)
    return response.data
  }

  async getSubEntitiesForBusinessEntity(businessEntityId: number): Promise<SubEntity[]> {
    const response = await this.api.get(`${this.url}/${businessEntityId}/sub-entities`)
    return response.data
  }

  async createBusinessEntity(businessEntity: BusinessEntity) {
    const response = await this.api.post(this.url, businessEntity)
    return response.data
  }

  async updateBusinessEntity(initialBusinessEntity: BusinessEntity, updatedBusinessEntity: BusinessEntity) {
    const createUpdatableBusinessEntity = (businessEntity: BusinessEntity) => {
      const { id: _, subEntities, ...updatableBusinessEntity } = businessEntity
      return updatableBusinessEntity
    }
    if (initialBusinessEntity.id !== updatedBusinessEntity.id) {
      //TODO - Fix this
      return Promise.reject(new Error("There was an err"))
    }
    let initialBusinessEntityWithUpdatableParameters = createUpdatableBusinessEntity(initialBusinessEntity)
    let updatedBusinessEntityWithUpdatableParameters = createUpdatableBusinessEntity(updatedBusinessEntity)

    let patchOperations = jsonpatch.compare(initialBusinessEntityWithUpdatableParameters, updatedBusinessEntityWithUpdatableParameters)
    const response = await this.api.patch(`${this.url}/${initialBusinessEntity.id}`, patchOperations)
    return response.data
  }

  async deleteBusinessEntity(businessEntityId: number) {
    await this.api.delete(`${this.url}/${businessEntityId}`)
    return
  }
  async getFullEntityTree() {
    const response = await this.api.get(`${this.url}/tree`)
    return response.data
  }
}
