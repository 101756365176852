enum ActionType {
  RELOAD_OBJECTS = "reload_objects",
  START_LOADING = "start_loading",
  STOP_LOADING = "stop_loading",
  UPDATE_PARAMETER = "update_parameter",
  RESET_PARAMETERS = "reset_parameters",
  UPLOAD_FILE = "upload_file",
  SET_ERROR_EXISTS = "set_error_exists",

  SET_BUSINESS_ENTITY_ID = "set_business_entity_id",
  SET_POSSIBLE_REVISON_NUMBERS = "set_possible_revison_numbers",
  SET_BUSINESS_PLAN_SESSION = "set_business_plan_session",
  SET_DESEASONALIZED_BUSINESS_PLAN_SESSION = "set_deseasonalized_business_plan_session",
  SET_BUSINESS_PLANS = "set_business_plans",
  ENABLE_FILE_SELECTION = "enable_file_selection",
  DISABLE_FILE_SELECTION = "disable_file_selection",
  ENABLE_FILE_UPLOAD = "enable_file_upload",
  DISABLE_FILE_UPLOAD = "disable_file_upload",
  SHOW_DELETE_BUSINESS_PLAN_SESSION_MODAL = "show_delete_business_plan_session_modal",
  HIDE_DELETE_BUSINESS_PLAN_SESSION_MODAL = "hide_delete_business_plan_session_modal",
  DELETE_BUSINESS_PLAN_SESSION = "delete_business_plan_session",
  SET_BUSINESS_PLANS_TO_UPLOAD = "set_business_plans_to_upload",
  SET_BUSINESS_ENTITIES = "set_business_entities",
  SET_PROJECTS = "set_projects",
  SET_SPEND_CLASS_TYPES = "set_spend_class_types",
  SET_SPEND_CLASSES = "set_spend_classes",
  TOGGLE_INCLUDE_ACTIVE_BUISNESS_ENTITIES = "toggle_include_active_business_entities",

  CLEAR_OBJECT_LIST = "clear_object_list",
  SET_OBJECT_LIST = "set_object_list",
  ADD_NEW_OBJECT_TO_OBJECT_LIST = "add_new_object_to_object_list",
  ADD_SUBS_TO_OBJECT = "add_subs_to_object",
  ADD_CHILD_OBJECTS_TO_OBJECT = "add_child_objects_to_object",
  RESET_ADD_OR_EDIT_OBJECT = "reset_add_or_edit_object",
  SET_PARENT_ID_OF_ADD_OR_EDIT_OBJECT = "set_parent_id_of_add_or_edit_object",
  CHANGE_ADD_OR_EDIT_MODAL_TITLE = "change_add_or_edit_modal_title",
  CHANGE_ADD_OR_EDIT_OBJECT_EDIT_MODE = "change_add_or_edit_object_edit_mode",
  CHANGE_ADD_OR_EDIT_OBJECT_VALUES = "change_add_or_edit_object_values",
  TOGGLE_ADD_OR_EDIT_OBJECT_MODAL = "toggle_add_or_edit_object_modal",
  SET_OBJECT_TO_CHANGE_STATUS = "set_object_to_change_status",
  SHOW_CHANGE_STATUS_DIALOG = "show_change_status_dialog",
  HIDE_CHANGE_STATUS_DIALOG = "hide_change_status_dialog",
  INCREASE_CHANGE_STATUS_COUNT = "increase_change_status_count",
  ENABLE_SUBMIT = "enable_submit",
  DISABLE_SUBMIT = "disable_submit",
  INCREASE_SUBMIT_COUNT = "increase_submit_count",
  SET_SAVING = "set_saving",
  UPDATE_EDITED_OBJECT = "update_edited_object",
  UPDATE_EDITED_SUB_OBJECT = "update_edited_sub_object",
  TOGGLE_OBJECT_ORG_CHART_MODAL = "toggle_object_org_chart_modal",
  SET_OBJECT_FOR_ORG_CHART = "set_object_for_org_chart",

  INCREASE_LOAD_COUNT = "increase_load_count",
  CHANGE_UPLOAD_PARAMETERS = "change_upload_parameters",
  FILE_UPLOADED = "file_uploaded",
  FILE_INPUT_EMPTY = "file_input_empty",

  ENABLE_EXECEPTION_OCCURED = "exception_occured",
  DISABLE_EXECEPTION_OCCURED = "disable_exception_occured",
  ENABLE_UPLOAD_BUTTON_BY_FILEUPLOAD = "enable_upload_button_by_fileupload",
  IS_UPLOAD_DISABLED = "is_upload_disabled"
}

export default ActionType
//isUploadDisabled
