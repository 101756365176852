import { FC, useEffect, useMemo } from "react"

import { useImmerReducer } from "use-immer"

import Page from "../../../components/Page"
import LoadCandEDataForm from "../../../components/LoadCandEDataForm"

import "./LoadCandEDataAdminPage.scss"
import { LoadCandEAdminDispatchContext, LoadCandEAdminStateContext } from "../../../state/LoadCandEAdminContext"
import { DataModel } from "../../../types"
import CandEStagingDataList from "../../../components/CandEStagingDataList"
import ActualValueDataService from "../../../services/api/actualValue.service"
import { Dialog } from "primereact/dialog"
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog"
import Loading from "../../../components/Loading"
import { Toaster } from "../../../services/toaster.service"

interface LoadCandEDataAdminPageProps { }

interface LoadCandEDataAdminState {
  month: Date | null
  dataSource: number
  loadCount: number
  isLoading: boolean
  processCount: number
  isProcessing: boolean
  data: DataModel[]
  hadErrorLoadingData: boolean
}

const LoadCandEDataAdminPage: FC<LoadCandEDataAdminPageProps> = () => {
  const initialState: LoadCandEDataAdminState = {
    month: null,
    dataSource: 0,
    loadCount: 0,
    isLoading: false,
    processCount: 0,
    isProcessing: false,
    data: [] as DataModel[],
    hadErrorLoadingData: false
  }

  const actualValueService = useMemo(() => new ActualValueDataService(), [])

  function stateReducer(draft, action) {
    const showExistingDataConfirmationDIalog = () => {
      confirmDialog({
        message: "Data already exist for the selected month. Do you want to delete existing data?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        acceptClassName: "p-button-danger",
        accept: () => {
          dispatch({ type: "increaseProcessCount" })
        }
      })
    }
    switch (action.type) {
      case "setMonth":
        draft.month = action.value
        return
      case "setDataSource":
        draft.dataSource = action.value
        return
      case "increaseLoadCount":
        draft.loadCount++
        return
      case "setIsLoading":
        draft.isLoading = action.value
        return
      case "setIsProcessing":
        draft.isProcessing = action.value
        return
      case "clearData":
        draft.data = []
        return
      case "setData":
        draft.data = action.value
        return
      case "increaseProcessCount":
        draft.processCount++
        return
      case "process":
        if (draft.data && draft.data.length) {
          showExistingDataConfirmationDIalog()
        } else {
          draft.processCount++
        }
        return
      case "setHadErrorLoadingData":
        draft.hadErrorLoadingData = action.value
        return
    }
  }

  const [state, dispatch] = useImmerReducer(stateReducer, initialState)

  const monthNames = useMemo(() => ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"], [])

  useEffect(() => {
    if (state.month) {
      dispatch({ type: "increaseLoadCount" })
    }
  }, [state.month, dispatch])

  useEffect(() => {
    if (state.loadCount) {
      dispatch({ type: "setIsLoading", value: true })
      dispatch({ type: "clearData" })
      dispatch({ type: "setHadErrorLoadingData", value: false })
      actualValueService
        .getActualValuesForElementTypeMonthAndYear(state.month.getMonth() + 1, state.month.getFullYear(), 1)
        .then(data => {
          dispatch({ type: "setData", value: data })
          if (data.length) {
            Toaster.showWarn(`C & E data has been previously loaded for ${monthNames[state.month.getMonth()]}, ${state.month.getFullYear()}`)
          } else {
            Toaster.showInfo(`C & E data has not yet been loaded for ${monthNames[state.month.getMonth()]}, ${state.month.getFullYear()}`)
          }
        })
        .catch(error => {
          dispatch({ type: "setHadErrorLoadingData", value: true })
        })
        .finally(() => {
          dispatch({ type: "setIsLoading", value: false })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loadCount, dispatch, actualValueService])

  useEffect(() => {
    if (state.processCount) {
      dispatch({ type: "setIsProcessing", value: true })
      dispatch({ type: "clearData" })
      dispatch({ type: "setHadErrorLoadingData", value: false })
      actualValueService
        .loadActualValuesForElementTypeMonthAndYear(state.month.getMonth() + 1, state.month.getFullYear(), 1, true)
        .then(data => {
          dispatch({ type: "setData", value: data })
          Toaster.showSuccess(`C & E data for ${monthNames[state.month.getMonth()]}, ${state.month.getFullYear()} processed successfully`)
        })
        .catch(error => {
          dispatch({ type: "setHadErrorLoadingData", value: true })
        })
        .finally(() => {
          dispatch({ type: "setIsProcessing", value: false })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.processCount, dispatch, actualValueService])

  return (
    <LoadCandEAdminStateContext.Provider value={state}>
      <LoadCandEAdminDispatchContext.Provider value={dispatch}>
        <Page title="Load C&E Data">
          <div className="flex justify-content-center">
            <Dialog closable={false} visible={state.isLoading || state.isProcessing} onHide={null}>
              <Loading isLoading={true} />
            </Dialog>
          </div>
          <div className="flex justify-content-center">
            <ConfirmDialog />
          </div>
          <div className="LoadCandEDataAdminPage">
            <div className="card">
              <div className="card-body" style={{ marginTop: "20px" }}>
                <LoadCandEDataForm />
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <CandEStagingDataList />
              </div>
            </div>
          </div>
        </Page>
      </LoadCandEAdminDispatchContext.Provider>
    </LoadCandEAdminStateContext.Provider>
  )
}

export default LoadCandEDataAdminPage
