import React from "react"
import "./Loading.scss"

interface LoadingProps {
  isLoading: boolean
}

const Loading: React.FC<LoadingProps> = (props) =>
  props.isLoading && (
    <div className="page-wrapper">
      <div className="loading-wrapper">
        <div className="loading-dots">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      </div>
    </div>
  )

export default Loading
