import { FC, useContext, useRef, useEffect, useMemo, useState } from "react"

import { useFormik } from "formik"
import * as Yup from "yup"
import Papa from "papaparse"
import camelCase from "camelcase"

import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { Divider } from "primereact/divider"
import { FileUpload, ItemTemplateOptions, FileUploadUploadEvent, FileUploadHeaderTemplateOptions } from "primereact/fileupload"

import ActionTypes from "../../state/ActionTypes"

import { Enums } from "../../types"
import { UploadSeasonalizedBusinessPlanStateContext, UploadSeasonalizedBusinessPlanDispatchContext } from "../../state/Context"
import useConvertEntityToPrimeReactTreeNode from "../../hooks/useConvertEntityToPrimeReactTreeNode"

import { SeasonalizedBusinessPlanInput } from "../../types/BusinessPlan"
import BusinessPlanService from "../../services/api/businessPlan.service"
import { Tag } from "primereact/tag"
import { ProgressBar } from "primereact/progressbar"

import { TreeNode } from "primereact/treenode"
import useFlattenArray from "../../hooks/useFlattenArray"
import { TreeSelect, TreeSelectChangeEvent } from "primereact/treeselect"
import { SpendClassType } from "../../types/SpendClass"

import "./UploadSeasonalizedBusinessPlanForm.scss"
import AppStateContext from "../../state/AppStateContext"

import { findDuplicates } from "../../helpers/array-helpers"

interface UploadSeasonalizedBusinessPlanFormProps {
  formikRef: any
  fileUploadRef?: any
}

const UploadSeasonalizedBusinessPlanForm: FC<UploadSeasonalizedBusinessPlanFormProps> = props => {
  const convertBusinessEntityToNode = useConvertEntityToPrimeReactTreeNode()
  const flattenArray = useFlattenArray()

  const businessPlanService = useMemo(() => new BusinessPlanService(), [])

  const uploadSeasonalizedBusinessPlanState = useContext(UploadSeasonalizedBusinessPlanStateContext)
  const uploadSeasonalizedBusinessPlanDispatch = useContext(UploadSeasonalizedBusinessPlanDispatchContext)

  const [businessEntitiesAsNodes, setBusinessEntitiesAsNodes] = useState<TreeNode[]>([])
  const [totalSize, setTotalSize] = useState(0)

  const appState = useContext(AppStateContext)

  const formikRef = useRef(null)
  const yearRef = useRef(null)
  const fileUploadRef = useRef(null)
  const chooseOptions = { icon: "pi pi-fw pi-file", iconOnly: false, className: "custom-choose-btn ml-4 mr-4", label: "Choose file to upload" }

  const yearsForUpload = useMemo(() => {
    let currentYear = new Date().getFullYear()
    return [currentYear, currentYear + 1]
  }, [])

  const formik = useFormik({
    initialValues: {
      businessEntityId: uploadSeasonalizedBusinessPlanState.businessEntityId,
      year: uploadSeasonalizedBusinessPlanState.businessPlanSession.year,
      elementType: uploadSeasonalizedBusinessPlanState.businessPlanSession.elementType,
      spendClassType: uploadSeasonalizedBusinessPlanState.businessPlanSession.spendClassType
    },
    validationSchema: Yup.object({
      businessEntityId: Yup.string().required("Required"),
      year: Yup.number().required("Required"),
      elementType: Yup.number().required("Required"),
      spendClassType: Yup.object<SpendClassType>().required("Required")
    }),
    onSubmit: (values, actions) => {
      formikRef.current = actions
      uploadSeasonalizedBusinessPlanDispatch({
        type: ActionTypes.CHANGE_UPLOAD_PARAMETERS,
        value: values
      })
    }
  })

  const isFormFieldInvalid = name => !!(formik.touched[name] && formik.errors[name])

  const getFormErrorMessage = name => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>
  }

  useEffect(() => {
    if (uploadSeasonalizedBusinessPlanState.businessEntities.length) {
      let nodes = [] as TreeNode[]
      uploadSeasonalizedBusinessPlanState.businessEntities.forEach(businessEntity => {
        nodes.push(
          convertBusinessEntityToNode(businessEntity, false, entity => {
            return entity.type === "Hemi" && appState.userProfile.businessEntitiesAssignedToUserRoles.includes(entity.id)
          })
        )
      })

      setBusinessEntitiesAsNodes(nodes)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadSeasonalizedBusinessPlanState.businessEntities])

  useEffect(() => {
    if (uploadSeasonalizedBusinessPlanState.resetParameterCount) {
      formik.setFieldValue("businessEntityId", null)
      uploadSeasonalizedBusinessPlanDispatch({ type: ActionTypes.SET_POSSIBLE_REVISON_NUMBERS, value: [] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadSeasonalizedBusinessPlanState.resetParameterCount])

  useEffect(() => {
    uploadSeasonalizedBusinessPlanDispatch({ type: ActionTypes.SET_BUSINESS_ENTITY_ID, value: formik.values.businessEntityId })
    formik.setFieldValue("year", null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.businessEntityId])

  useEffect(() => {
    uploadSeasonalizedBusinessPlanDispatch({ type: ActionTypes.UPDATE_PARAMETER, parameter: "year", value: formik.values.year })
    formik.setFieldValue("elementType", null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.year])

  useEffect(() => {
    uploadSeasonalizedBusinessPlanDispatch({ type: ActionTypes.UPDATE_PARAMETER, parameter: "elementType", value: formik.values.elementType })
    formik.setFieldValue("spendClassType", null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.elementType])

  useEffect(() => {
    uploadSeasonalizedBusinessPlanDispatch({ type: ActionTypes.UPDATE_PARAMETER, parameter: "spendClassType", value: formik.values.spendClassType })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.spendClassType])

  useEffect(() => {
    if (uploadSeasonalizedBusinessPlanState.spendClassTypes.length === 1) {
      formik.setFieldValue("spendClassType", uploadSeasonalizedBusinessPlanState.spendClassTypes[0])
    } else {
      formik.setFieldValue("spendClassType", null)
    }
  }, [uploadSeasonalizedBusinessPlanState.spendClassTypes])

  useEffect(() => {
    if (fileUploadRef.current) {
      props.fileUploadRef.current = fileUploadRef.current
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadRef])

  function getValue(stringValue: string): number {
    return parseFloat(stringValue)
  }

  function handleFileSelection(data: any[]): SeasonalizedBusinessPlanInput[] {
    const classOrProjectProperty = uploadSeasonalizedBusinessPlanState.businessPlanSession.elementType === Enums.ElementType.CAPEX.value ? "project" : "class"

    var transformedBusinessPlans = data
      .filter(result => result[classOrProjectProperty])
      .map(
        result =>
          ({
            class: {
              error: null,
              name: result[classOrProjectProperty]
            },
            jan: {
              error: null,
              value: getValue(result.jan)
            },
            feb: {
              error: null,
              value: getValue(result.feb)
            },
            mar: {
              error: null,
              value: getValue(result.mar)
            },
            apr: {
              error: null,
              value: getValue(result.apr)
            },
            may: {
              error: null,
              value: getValue(result.may)
            },
            jun: {
              error: null,
              value: getValue(result.jun)
            },
            jul: {
              error: null,
              value: getValue(result.jul)
            },
            aug: {
              error: null,
              value: getValue(result.aug)
            },
            sep: {
              error: null,
              value: getValue(result.sep)
            },
            oct: {
              error: null,
              value: getValue(result.oct)
            },
            nov: {
              error: null,
              value: getValue(result.nov)
            },
            dec: {
              error: null,
              value: getValue(result.dec)
            },
            total: {
              error: null,
              value: getValue(result.total)
            }
          } as SeasonalizedBusinessPlanInput)
      )

    return transformedBusinessPlans
  }

  function validateBusinessEntityAndUpdateBusinessEntityId(businessPlans: SeasonalizedBusinessPlanInput[]): SeasonalizedBusinessPlanInput[] {
    const plansWithoutTotalClass = businessPlans.filter(x => x.class.name.toLowerCase() !== "Total".toLowerCase())
    const totalClass = businessPlans.filter(x => x.class.name.toLowerCase() === "Total".toLowerCase())[0]

    const { class: _, total, ...monthKeyValues } = totalClass
    const monthKeys = Object.keys(monthKeyValues)
    for (let month of monthKeys) {
      const sumForMonth = plansWithoutTotalClass.reduce((accumulator, currentPlan) => accumulator + currentPlan[month].value, 0)
      if (Math.abs(sumForMonth - totalClass[month].value) > 0.12) {
        totalClass[month].error = `The total value does not match the expected sum (${"$" + sumForMonth})`
      }
    }

    let totalSum = 0.0

    let duplicatedClassNames = findDuplicates(plansWithoutTotalClass.map(x => x.class.name.toLowerCase()))

    for (let businessPlan of businessPlans) {
      const sumForBusinessPlan = Object.keys(businessPlan)
        .filter(x => x.toLowerCase() !== "Class".toLowerCase() && x.toLowerCase() !== "Total".toLowerCase())
        .reduce((accumulator, month) => accumulator + businessPlan[month].value, 0)

      if (businessPlan.class.name.toLowerCase() !== "Total".toLowerCase()) {
        totalSum += sumForBusinessPlan
      }

      if (Math.abs(sumForBusinessPlan - businessPlan.total.value) > 0.12) {
        businessPlan.total.error = `The total value does not match the expected sum (${"$" + sumForBusinessPlan.toLocaleString(undefined, { minimumFractionDigits: 5 })})`
      }

      let matchingClassesOrProjects = null
      if (businessPlan.class.name.toLowerCase() !== "Total".toLowerCase()) {
        if (uploadSeasonalizedBusinessPlanState.businessPlanSession.elementType === Enums.ElementType.CAPEX.value) {
          matchingClassesOrProjects = uploadSeasonalizedBusinessPlanState.projects.filter(x => x.name.toLowerCase() !== "Total".toLowerCase() && (x.name.toLowerCase() === businessPlan.class.name.toLowerCase() || x.alternateName?.toLowerCase() === businessPlan.class.name.toLowerCase()))
          if (!matchingClassesOrProjects.length) {
            businessPlan.class.error = `Project not found.`
          }
        } else {
          matchingClassesOrProjects = uploadSeasonalizedBusinessPlanState.spendClasses.filter(x => x.name.toLowerCase() !== "Total".toLowerCase() && x.name.toLowerCase() === businessPlan.class.name.toLowerCase())
          if (!matchingClassesOrProjects.length) {
            businessPlan.class.error = `Class not found.`
          }
        }
      }
      if (matchingClassesOrProjects && matchingClassesOrProjects.length) {
        businessPlan.class.value = matchingClassesOrProjects[0].id
      }

      if (duplicatedClassNames.filter(x => x === businessPlan.class.name.toLowerCase()).length && !businessPlan.class.error) {
        businessPlan.class.error = `Duplicate ${uploadSeasonalizedBusinessPlanState.businessPlanSession.elementType === Enums.ElementType.CAPEX.value ? "project" : "class"} found.`
      }
    }

    const difference = Math.abs(totalSum - uploadSeasonalizedBusinessPlanState.deseasonalizedusinessPlanSession.totalValue)
    if (difference > 0.12 && !totalClass["total"].error) {
      totalClass["total"].error = `The total value does not match the expected sum from deseasonalized business plan (${"$" + uploadSeasonalizedBusinessPlanState.deseasonalizedusinessPlanSession.totalValue.toLocaleString(undefined, { minimumFractionDigits: 5 })})`
    }

    return businessPlans
  }

  const onFileUpload = (e: FileUploadUploadEvent) => {
    let _totalSize = 0

    e.files.forEach(file => {
      _totalSize += file.size || 0
    })

    setTotalSize(_totalSize)
    // toast.current?.show({ severity: "info", summary: "Success", detail: "File Uploaded" })
  }
  const onFileSelect = e => {
    //IS_UPLOAD_DISABLED
    // uploadBusinessPlanDispatch({ type: ActionTypes.IS_UPLOAD_DISABLED, value: true })
    //setUploadDisabled(true)

    uploadSeasonalizedBusinessPlanDispatch({ type: ActionTypes.SET_BUSINESS_PLANS, value: [] as SeasonalizedBusinessPlanInput[] })
    if (e.files.length) {
      Papa.parse(e.files[0], {
        header: true,
        skipEmptyLines: true,
        transformHeader: function (h) {
          return camelCase(h)
        },
        transform: function (v) {
          return v.trim()
        },
        complete: function (results) {
          if (results.data.length) {
            const data = handleFileSelection(results.data)
            uploadSeasonalizedBusinessPlanDispatch({ type: ActionTypes.SET_BUSINESS_PLANS, value: validateBusinessEntityAndUpdateBusinessEntityId(data) })
          }
        }
      })
    }
  }
  const onTemplateRemove = (file: File, callback: Function) => {
    if (fileUploadRef.current.getFiles().length === 1) {
      uploadSeasonalizedBusinessPlanDispatch({ type: ActionTypes.SET_BUSINESS_PLANS, value: [] as SeasonalizedBusinessPlanInput[] })
      //setEnableUpload(false)

      setTotalSize(totalSize - file.size)
      callback()
      // uploadBusinessPlanDispatch({ type: ActionTypes.IS_UPLOAD_DISABLED, value: false })
      //setUploadDisabled(false)
    }
  }

  const onFileClear = () => {
    setTotalSize(0)
  }

  const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
    const { className, chooseButton } = options

    const value = totalSize / 10000

    return (
      <div className={className} style={{ backgroundColor: "#f8f9fa", display: "flex", alignItems: "center" }}>
        {!uploadSeasonalizedBusinessPlanState.isUploadDisabled ? chooseButton : <Button className="ml-4 mr-4" icon="pi pi-file" label="Choose file to upload" disabled={true} />}

        <div className="flex align-items-center gap-3 ml-auto">
          <ProgressBar value={value} showValue={false} style={{ width: "10rem", height: "12px" }}></ProgressBar>
        </div>
      </div>
    )
  }

  const itemTemplate = (inFile: object, props: ItemTemplateOptions) => {
    const file = inFile as File
    return (
      <div className="flex align-items-center flex-wrap" style={{ backgroundColor: "#f8f9fa" }}>
        <div className="flex align-items-center" style={{ width: "20%" }}>
          <span className="flex flex-column text-left ml-3" style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}>
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
        <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
      </div>
    )
  }

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column" style={{ height: "250px" }}>
        <i className="pi pi-file mt-3 p-5" style={{ fontSize: "5em", borderRadius: "50%", backgroundColor: "var(--surface-b)", color: "var(--surface-d)" }}></i>
        <span style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }} className="my-5">
          Drag and drop files to here to upload.
        </span>
      </div>
    )
  }

  return (
    <>
      <div className="AddOrEditEntityForm">
        <form className="row" ref={props.formikRef} onSubmit={formik.handleSubmit}>
          <div className="p-fluid col-md-3">
            <div className="p-field p-float-label">
              <TreeSelect
                id="businessEntityId"
                name="businessEntityId"
                value={formik.values.businessEntityId}
                options={businessEntitiesAsNodes}
                onChange={(e: TreeSelectChangeEvent) => {
                  formik.setFieldValue("businessEntityId", e.value)
                  return formik.handleChange
                }}
                className="w-full"
                placeholder="Select business entity"
              ></TreeSelect>

              <label htmlFor="elementType">
                Type<span className="text-danger font-weight-bold text-large">&nbsp;*</span>
              </label>
            </div>
            {getFormErrorMessage("elementType")}
            <Divider type="solid" style={{ visibility: "hidden" }} />
          </div>

          <div className="p-fluid col-md-3">
            <div className="p-field p-float-label">
              <Dropdown
                id="year"
                name="year"
                value={formik.values.year}
                options={yearsForUpload}
                placeholder="Select year"
                onChange={e => {
                  formik.setFieldValue("year", e.value)

                  return formik.handleChange
                }}
                onBlur={formik.handleBlur}
                autoFocus
                ref={yearRef}
                disabled={!formik.values.businessEntityId || uploadSeasonalizedBusinessPlanState.isLoading || uploadSeasonalizedBusinessPlanState.isUploading}
                className="w-full"
              />
              <label htmlFor="year">
                Year<span className="text-danger font-weight-bold text-large ">&nbsp;*</span>
              </label>
            </div>
            <div style={{ marginLeft: "20px" }}>{getFormErrorMessage("year")}</div>

            <Divider type="solid" style={{ visibility: "hidden" }} />
          </div>

          <div className="p-fluid col-md-3">
            <div className="p-field p-float-label">
              <Dropdown
                id="elementType"
                name="elementType"
                value={formik.values.elementType}
                options={Enums.ElementTypes}
                optionLabel="description"
                placeholder="Select type of plan to upload"
                onChange={e => {
                  formik.setFieldValue("elementType", e.value)
                  // uploadBusinessPlanDispatch({ type: ActionTypes.UPDATE_PARAMETER, parameter: "elementType", value: e.value })
                  return formik.handleChange
                }}
                onBlur={formik.handleBlur}
                disabled={!formik.values.year || uploadSeasonalizedBusinessPlanState.isLoading || uploadSeasonalizedBusinessPlanState.isUploading}
                className="w-full"
              />
              <label htmlFor="elementType">
                Type<span className="text-danger font-weight-bold text-large">&nbsp;*</span>
              </label>
            </div>
            {getFormErrorMessage("elementType")}
            <Divider type="solid" style={{ visibility: "hidden" }} />
          </div>

          <div className="p-fluid col-md-3">
            <div className="p-field p-float-label">
              <Dropdown
                id="spendClassType"
                name="spendClassType"
                value={formik.values.spendClassType}
                options={uploadSeasonalizedBusinessPlanState.spendClassTypes}
                optionLabel="name"
                placeholder="Select class type"
                onChange={e => {
                  formik.setFieldValue("spendClassType", e.value)
                  return formik.handleChange
                }}
                onBlur={formik.handleBlur}
                disabled={!formik.values.elementType || uploadSeasonalizedBusinessPlanState.isLoading || uploadSeasonalizedBusinessPlanState.isUploading}
                className="w-full"
              />
              <label htmlFor="spendClassType">
                Class type<span className="text-danger font-weight-bold text-large">&nbsp;*</span>
              </label>
            </div>
            {getFormErrorMessage("spendClassType")}
          </div>
          <div className="p-fluid col-md-5 justify-content-end"></div>
          <div className="p-fluid col-md-5 justify-content-end"></div>

          <div className="flex align-items-center justify-content-end gap-2">
            <div className="p-field p-float-label">
              <Button type="button" icon="pi pi-times" label="Reset all" className="p-button-danger ml-auto mr-4" onClick={() => uploadSeasonalizedBusinessPlanDispatch({ type: ActionTypes.RESET_PARAMETERS })} />
            </div>
          </div>

          {uploadSeasonalizedBusinessPlanState.enableFileSelect && !!!uploadSeasonalizedBusinessPlanState.businessPlanSession.businessPlans.length ? (
            <div>
              <br />
              <FileUpload ref={fileUploadRef} auto={false} accept=".csv" maxFileSize={1000000} onUpload={onFileUpload} onSelect={onFileSelect} onError={onFileClear} onClear={onFileClear} headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate} chooseOptions={chooseOptions} />
            </div>
          ) : (
            <></>
          )}
        </form>
      </div>
    </>
  )
}

export default UploadSeasonalizedBusinessPlanForm
