import React from "react"

import { BsCheckCircle } from "react-icons/bs"
import { MdOutlineErrorOutline } from "react-icons/md"
import { VscError } from "react-icons/vsc"

import "./CustomToast.scss"

interface CustomToastProp {
  heading: string
  message: string
}

const CustomToast: React.FC<CustomToastProp> = ({ heading, message }) => {
  const icon = (iconHead: string) => {
    switch (iconHead) {
      case "Success":
        return <BsCheckCircle />
      case "Error":
        return <VscError />
      case "Warning":
        return <MdOutlineErrorOutline />
      default:
        return <MdOutlineErrorOutline />
    }
  }

  return (
    <div className="d-flex w-100 align-items-center justify-content-center">
      {icon(heading)}
      <span className="bold mx-2">{heading}:</span>
      {message}
    </div>
  )
}

export default CustomToast
