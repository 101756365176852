import { Nullable } from "primereact/ts-helpers"
import { AddSeasionalizedViewModel, Seasionalized } from "../../types/Seasionalized"
import { BusinessPlanSession } from "../../types/BusinessPlanSession"
import { APIService } from "./api.service"

export default class SeasionalizedService extends APIService {
  private url = "/api/businessPlans"

  async getSeasionalized(businessEntityId: string, year: number, elementType: number, revisionNumber: number): Promise<Seasionalized[]> {
    const response = await this.api.get(`${this.url}/${businessEntityId}/${year}/${elementType}?revisionNumber=${revisionNumber}&seasonalized=true`)
    return response.data.items
  }
  async getLastSeasionalized(businessEntityId: string, year: number, elementType: number): Promise<Seasionalized[]> {
    const response = await this.api.get(`${this.url}/session/seasonalized/last/${businessEntityId}/${year}/${elementType}`)
    return response.data.items
  }
  async getUnSeasionalized(businessEntityId: string, year: number, elementType: number): Promise<BusinessPlanSession> {
    const response = await this.api.get(`${this.url}/session/unseasonalized/last/${businessEntityId}/${year}/${elementType}`)

    return response.data
  }
  async getUnSeasionalizedData(year: number, elementType: number, revisionNumber: Nullable<number>): Promise<BusinessPlanSession> {
    const url = revisionNumber ? `${this.url}/session/unseasonalized/${year}/${elementType}?revisionNumber=${revisionNumber}` : `${this.url}/session/unseasonalized/${year}/${elementType}`

    const response = await this.api.get(url)
    return response.data
  }
  async deleteExistingBusinessPlans(businessPlanSessionId: string): Promise<BusinessPlanSession> {
    const response = await this.api.delete(`${this.url}/${businessPlanSessionId}`)
    return response.data
  }
  async uploadBusinessPlan(year: number, elementType: number, revisionNumber: number, file: File): Promise<BusinessPlanSession> {
    let formData = new FormData()
    formData.append("elementType", elementType.toString())
    formData.append("year", year.toString())
    formData.append("revisionNumber", revisionNumber.toString())
    formData.append("file", file)

    const response = await this.api.post(`${this.url}/load/midas`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    console.log(response.data)

    return response.data
  }

  async createSeasionalized(entity: AddSeasionalizedViewModel): Promise<AddSeasionalizedViewModel> {
    const response = await this.api.post(`${this.url}/load`, entity)
    return response.data
  }
}
