import { useCallback } from "react"
import { Enums } from "../types"

export default function useConvertVersionNumberToString() {
    const convertVersionNumberToString = useCallback((versninNumber: number): string => {
        const theVersionNumber = Enums.RevisionNumber.find(x => x.value === versninNumber)
        return theVersionNumber ? theVersionNumber.description : null
    }, [])

    return convertVersionNumberToString
}
