import { createContext } from "react"
import { UploadDeseasonalizedBusinessPlanState } from "./UploadDeasonalizedBusinessPlan"
import { UploadSeasonalizedBusinessPlanState } from "./UploadSeasonalizedBusinessPlan"

export const ManageEntityStateContext = createContext(null)
export const ManageEntityDispatchContext = createContext(null)

export const ManageElementsStateContext = createContext(null)
export const ManageElementsDispatchContext = createContext(null)

export const UploadDeseasonalizedBusinessPlanStateContext = createContext<UploadDeseasonalizedBusinessPlanState>(null)
export const UploadDeseasonalizedBusinessPlanDispatchContext = createContext(null)

export const UploadSeasonalizedBusinessPlanStateContext = createContext<UploadSeasonalizedBusinessPlanState>(null)
export const UploadSeasonalizedBusinessPlanDispatchContext = createContext(null)

export const SeasonalizedBusinessPlanStateContext = createContext(null)
export const SeasonalizedBusinessPlanDispatchContext = createContext(null)
