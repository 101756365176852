import { FC, useContext, useRef, useEffect, useMemo, useState } from "react"

import { useFormik } from "formik"
import * as Yup from "yup"
import Papa from "papaparse"
import camelCase from "camelcase"

import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { Divider } from "primereact/divider"
import { FileUpload, FileUploadSelectEvent, FileUploadRemoveEvent, ItemTemplateOptions, FileUploadUploadEvent, FileUploadHeaderTemplateOptions } from "primereact/fileupload"

import ActionTypes from "../../state/ActionTypes"

import { Enums } from "../../types"
import { UploadDeseasonalizedBusinessPlanDispatchContext, UploadDeseasonalizedBusinessPlanStateContext } from "../../state/Context"

import { BusinessPanType, MIDASBusinessPlanInput } from "../../types/BusinessPlan"
import BusinessPlanService from "../../services/api/businessPlan.service"
import { Tag } from "primereact/tag"
import { ProgressBar } from "primereact/progressbar"

import "./UploadDeasonalizedBusinessPlanForm.scss"

interface UploadDeasonalizedBusinessPlanFormProps {
  formikRef: any
  fileUploadRef?: any
}

const UploadDeasonalizedBusinessPlanForm: FC<UploadDeasonalizedBusinessPlanFormProps> = props => {
  // const [possibleRevisionNumbers, setPossibleRevisionNumbers] = useState<{ value: number; description: string }[]>([])

  const businessPlanService = useMemo(() => new BusinessPlanService(), [])

  const uploadBusinessPlanState = useContext(UploadDeseasonalizedBusinessPlanStateContext)
  const uploadBusinessPlanDispatch = useContext(UploadDeseasonalizedBusinessPlanDispatchContext)
  // const [isUploadDisabled, setUploadDisabled] = useState(false)
  const [totalSize, setTotalSize] = useState(0)

  const formikRef = useRef(null)
  const yearRef = useRef(null)
  const fileUploadRef = useRef(null)
  const chooseOptions = { icon: "pi pi-fw pi-file", iconOnly: false, className: "custom-choose-btn ml-4 mr-4", label: "Choose file to upload" }

  const yearsForUpload = useMemo(() => {
    let currentYear = new Date().getFullYear()
    return [currentYear, currentYear + 1]
  }, [])

  const formik = useFormik({
    initialValues: {
      year: uploadBusinessPlanState.businessPlanSession.year,
      elementType: uploadBusinessPlanState.businessPlanSession.elementType,
      revisionNumber: uploadBusinessPlanState.businessPlanSession.revisionNumber
    },
    validationSchema: Yup.object({
      year: Yup.number().required("Required"),
      elementType: Yup.number().required("Required"),
      revisionNumber: Yup.number().required("Required")
    }),
    onSubmit: (values, actions) => {
      formikRef.current = actions
      uploadBusinessPlanDispatch({
        type: ActionTypes.CHANGE_UPLOAD_PARAMETERS,
        value: values
      })
    }
  })

  const isFormFieldInvalid = name => !!(formik.touched[name] && formik.errors[name])

  const getFormErrorMessage = name => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>
  }

  useEffect(() => {
    if (uploadBusinessPlanState.resetParameterCount) {
      formik.setFieldValue("year", null)
      uploadBusinessPlanDispatch({ type: ActionTypes.SET_POSSIBLE_REVISON_NUMBERS, value: [] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadBusinessPlanState.resetParameterCount])

  useEffect(() => {
    uploadBusinessPlanDispatch({ type: ActionTypes.UPDATE_PARAMETER, parameter: "year", value: formik.values.year })
    formik.setFieldValue("elementType", null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.year])

  useEffect(() => {
    uploadBusinessPlanDispatch({ type: ActionTypes.UPDATE_PARAMETER, parameter: "elementType", value: formik.values.elementType })
    formik.setFieldValue("revisionNumber", null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.elementType])

  useEffect(() => {
    uploadBusinessPlanDispatch({ type: ActionTypes.UPDATE_PARAMETER, parameter: "revisionNumber", value: formik.values.revisionNumber })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.revisionNumber])

  useEffect(() => {
    if (uploadBusinessPlanState.businessPlanSession.year && uploadBusinessPlanState.businessPlanSession.elementType) {
      uploadBusinessPlanDispatch({ type: ActionTypes.START_LOADING })
      uploadBusinessPlanDispatch({ type: ActionTypes.SET_POSSIBLE_REVISON_NUMBERS, value: [] })
      businessPlanService
        .getRevisionNumberForLastLoadedBusinessPlanningSession(uploadBusinessPlanState.businessPlanSession.year, uploadBusinessPlanState.businessPlanSession.elementType, BusinessPanType.normal)
        .then(result => {
          if (!result && result !== 0) {
            uploadBusinessPlanDispatch({
              type: ActionTypes.SET_POSSIBLE_REVISON_NUMBERS,
              value: [
                {
                  value: 0,
                  description: "Base version"
                }
              ]
            })
          } else {
            const firstRevisionNumber = result
            uploadBusinessPlanDispatch({
              type: ActionTypes.SET_POSSIBLE_REVISON_NUMBERS,
              value: [
                {
                  value: firstRevisionNumber,
                  description: firstRevisionNumber === 0 ? "Base version" : `Revision ${firstRevisionNumber}`
                },
                {
                  value: firstRevisionNumber + 1,
                  description: `Revision ${firstRevisionNumber + 1}`
                }
              ]
            })
          }
        })
        .catch(() => {})
        .finally(() => uploadBusinessPlanDispatch({ type: ActionTypes.STOP_LOADING }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadBusinessPlanState.businessPlanSession.year, uploadBusinessPlanState.businessPlanSession.elementType])

  // useEffect(() => {
  //   //console.log(uploadBusinessPlanState.enableUploadButtonByFileUpload)
  //   if (uploadBusinessPlanState.exception) {
  //     setEnableUpload(false)
  //   } else if (!uploadBusinessPlanState.businessPlanSession.year || !uploadBusinessPlanState.businessPlanSession.elementType || uploadBusinessPlanState.businessPlanSession.revisionNumber === null) {
  //     setEnableUpload(false)
  //   } else {
  //     setEnableUpload(Object.keys(uploadBusinessPlanState.loadedBusinessPlans).length === 0)
  //   }
  // }, [uploadBusinessPlanState.enableUploadButtonByFileUpload, uploadBusinessPlanState.exception, uploadBusinessPlanState.businessPlanSession.year, uploadBusinessPlanState.businessPlanSession.elementType, uploadBusinessPlanState.businessPlanSession.revisionNumber, uploadBusinessPlanState.loadedBusinessPlans])

  useEffect(() => {
    if (fileUploadRef.current) {
      props.fileUploadRef.current = fileUploadRef.current
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadRef])
  // useEffect(() => {
  //   if (year && elementType && revisionNumber != null) {
  //     uploadBusinessPlanDispatch({ type: ActionTypes.CHANGE_UPLOAD_PARAMETERS, value: { year, elementType, revisionNumber } })
  //     setFieldsAreValid(true)
  //   }
  // }, [year, elementType, revisionNumber, uploadBusinessPlanDispatch])

  function handleFileSelection(data: any[]): MIDASBusinessPlanInput[] {
    return data.map(x => ({
      accountId: {
        error: null,
        value: x.accountId
      },
      businessEntityId: {
        error: null,
        value: null
      },
      businessEntityBusinessPlanDataSourceId: {
        error: null,
        value: x.entityId
      },
      businessEntityName: {
        error: null,
        value: x.entity
      },
      value: {
        error: null,
        value: parseFloat(x.measures)
      }
    }))
  }

  function sumBusinessPlanValuesByEntityGroupName(businessPlans: MIDASBusinessPlanInput[]): MIDASBusinessPlanInput[] {
    const businessPlansSummedByBusinessPlanningDataSourceId = businessPlans
      .filter(x => x.accountId.value !== "CE_TOTAL")
      .reduce((group, businessPlan) => {
        const { businessEntityBusinessPlanDataSourceId } = businessPlan
        group[businessEntityBusinessPlanDataSourceId.value] = group[businessEntityBusinessPlanDataSourceId.value] ?? {
          accountId: {
            error: null,
            value: businessPlan.accountId.value
          },
          businessEntityId: {
            error: null,
            value: businessPlan.businessEntityId.value
          },
          businessEntityBusinessPlanDataSourceId: {
            error: null,
            value: businessPlan.businessEntityBusinessPlanDataSourceId.value
          },
          businessEntityName: {
            error: null,
            value: businessPlan.businessEntityName.value
          },
          value: {
            error: null,
            value: 0
          }
        }
        group[businessEntityBusinessPlanDataSourceId.value].value.value += businessPlan.value.value
        return group
      }, {})

    return Object.keys(businessPlansSummedByBusinessPlanningDataSourceId).map(function (key) {
      return {
        accountId: {
          error: businessPlansSummedByBusinessPlanningDataSourceId[key].accountId.error,
          value: businessPlansSummedByBusinessPlanningDataSourceId[key].accountId.value
        },
        businessEntityId: {
          error: businessPlansSummedByBusinessPlanningDataSourceId[key].businessEntityId.error,
          value: businessPlansSummedByBusinessPlanningDataSourceId[key].businessEntityId.value
        },
        businessEntityBusinessPlanDataSourceId: {
          error: businessPlansSummedByBusinessPlanningDataSourceId[key].businessEntityBusinessPlanDataSourceId.error,
          value: businessPlansSummedByBusinessPlanningDataSourceId[key].businessEntityBusinessPlanDataSourceId.value
        },
        businessEntityName: {
          error: businessPlansSummedByBusinessPlanningDataSourceId[key].businessEntityName.error,
          value: businessPlansSummedByBusinessPlanningDataSourceId[key].businessEntityName.value
        },
        value: {
          error: businessPlansSummedByBusinessPlanningDataSourceId[key].value.error,
          value: businessPlansSummedByBusinessPlanningDataSourceId[key].value.value
        }
      } as MIDASBusinessPlanInput
    })
  }

  function validateBusinessEntityAndUpdateBusinessEntityId(businessPlans: MIDASBusinessPlanInput[]) {
    for (let businessPlan of businessPlans) {
      var matchingBusinessEntities = uploadBusinessPlanState.businessEntities.filter(x => x.businessPlanDataSourceId && x.businessPlanDataSourceId.trim() === businessPlan.businessEntityBusinessPlanDataSourceId.value.trim())
      if (!matchingBusinessEntities.length) {
        businessPlan.businessEntityName.error = businessPlan.businessEntityName.error = businessPlan.businessEntityName.error = "Business entity not found"
      } else {
        businessPlan.businessEntityId.value = matchingBusinessEntities[0].id
      }
    }
  }

  function validateCAPEXTotals(businessPlans: MIDASBusinessPlanInput[], normalizedBusinessPlans: MIDASBusinessPlanInput[]) {
    const totals = businessPlans.filter(x => x.accountId.value === "CE_TOTAL")
    for (let businessPlan of normalizedBusinessPlans) {
      const line = totals.filter(x => x.businessEntityBusinessPlanDataSourceId.value === businessPlan.businessEntityBusinessPlanDataSourceId.value)[0]
      if (!line) {
        businessPlan.value.error = `Cannot find the total line for ${businessPlan.businessEntityName.value}`
      } else if (line.value.value.toFixed(5) !==businessPlan.value.value.toFixed(5)) {
        console.log(line, businessPlan)
        console.log(line.value.value, businessPlan.value.value)
        businessPlan.value.error = `The total value does not match the expected sum - $${line.value.value.toLocaleString()}`
      }
    }
  }

  function normalizeAndValidateBusinessPlansToUpload(businessPlans: MIDASBusinessPlanInput[]): MIDASBusinessPlanInput[] {
    const normalizedBusinessPlans = sumBusinessPlanValuesByEntityGroupName(businessPlans)
    validateBusinessEntityAndUpdateBusinessEntityId(normalizedBusinessPlans)
    if (uploadBusinessPlanState.businessPlanSession.elementType === Enums.ElementType.CAPEX.value) {
      validateCAPEXTotals(businessPlans, normalizedBusinessPlans)
    }
    return normalizedBusinessPlans
  }

  const onFileUpload = (e: FileUploadUploadEvent) => {
    let _totalSize = 0

    e.files.forEach(file => {
      _totalSize += file.size || 0
    })

    setTotalSize(_totalSize)
    // toast.current?.show({ severity: "info", summary: "Success", detail: "File Uploaded" })
  }
  const onFileSelect = e => {
    //IS_UPLOAD_DISABLED
    // uploadBusinessPlanDispatch({ type: ActionTypes.IS_UPLOAD_DISABLED, value: true })
    //setUploadDisabled(true)

    uploadBusinessPlanDispatch({ type: ActionTypes.SET_BUSINESS_PLANS, value: [] as MIDASBusinessPlanInput[] })
    if (e.files.length) {
      Papa.parse(e.files[0], {
        header: true,
        skipEmptyLines: true,
        transformHeader: function (h) {
          return camelCase(h)
        },
        transform: function (v) {
          return v.trim()
        },
        complete: function (results) {
          if (results.data.length) {
            console.log(results.data)
            const data = handleFileSelection(results.data)
            uploadBusinessPlanDispatch({ type: ActionTypes.SET_BUSINESS_PLANS, value: normalizeAndValidateBusinessPlansToUpload(data) })
          }
        }
      })
    }
  }
  const onTemplateRemove = (file: File, callback: Function) => {
    if (fileUploadRef.current.getFiles().length === 1) {
      uploadBusinessPlanDispatch({ type: ActionTypes.SET_BUSINESS_PLANS, value: [] as MIDASBusinessPlanInput[] })
      //setEnableUpload(false)

      setTotalSize(totalSize - file.size)
      callback()
      // uploadBusinessPlanDispatch({ type: ActionTypes.IS_UPLOAD_DISABLED, value: false })
      //setUploadDisabled(false)
    }
  }

  const onFileClear = () => {
    setTotalSize(0)
  }

  const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
    const { className, chooseButton } = options

    const value = totalSize / 10000

    return (
      <div className={className} style={{ backgroundColor: "#f8f9fa", display: "flex", alignItems: "center" }}>
        {!uploadBusinessPlanState.isUploadDisabled ? chooseButton : <Button className="ml-4 mr-4" icon="pi pi-file" label="Choose file to upload" disabled={true} />}

        <div className="flex align-items-center gap-3 ml-auto">
          <ProgressBar value={value} showValue={false} style={{ width: "10rem", height: "12px" }}></ProgressBar>
        </div>
      </div>
    )
  }

  const itemTemplate = (inFile: object, props: ItemTemplateOptions) => {
    const file = inFile as File
    return (
      <div className="flex align-items-center flex-wrap" style={{ backgroundColor: "#f8f9fa" }}>
        <div className="flex align-items-center" style={{ width: "20%" }}>
          <span className="flex flex-column text-left ml-3" style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}>
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
        <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
      </div>
    )
  }

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column" style={{ height: "250px" }}>
        <i className="pi pi-file mt-3 p-5" style={{ fontSize: "5em", borderRadius: "50%", backgroundColor: "var(--surface-b)", color: "var(--surface-d)" }}></i>
        <span style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }} className="my-5">
          Drag and drop files to here to upload.
        </span>
      </div>
    )
  }
  return (
    <>
      <div className="AddOrEditEntityForm">
        <form className="row g-3" ref={props.formikRef} onSubmit={formik.handleSubmit}>
          <div className="p-fluid col-md-4">
            <div className="p-field p-float-label mt-4 ml-4 mb-2">
              <Dropdown
                id="year"
                name="year"
                value={formik.values.year}
                options={yearsForUpload}
                placeholder="Select year"
                onChange={e => {
                  formik.setFieldValue("year", e.value)

                  return formik.handleChange
                }}
                onBlur={formik.handleBlur}
                autoFocus
                ref={yearRef}
                disabled={uploadBusinessPlanState.isLoading || uploadBusinessPlanState.isUploading}
                className="w-full"
              />
              <label htmlFor="year">
                Year<span className="text-danger font-weight-bold text-large ">&nbsp;*</span>
              </label>
            </div>
            <div style={{ marginLeft: "20px" }}>{getFormErrorMessage("year")}</div>

            <Divider type="solid" style={{ visibility: "hidden" }} />
          </div>

          <div className="p-fluid col-md-4">
            <div className="p-field p-float-label mb-2 mt-4">
              <Dropdown
                id="elementType"
                name="elementType"
                value={formik.values.elementType}
                options={Enums.ElementTypes.filter(x => x.value != Enums.ElementType.Production.value)}
                optionLabel="description"
                placeholder="Select type of plan to upload"
                onChange={e => {
                  formik.setFieldValue("elementType", e.value)
                  // uploadBusinessPlanDispatch({ type: ActionTypes.UPDATE_PARAMETER, parameter: "elementType", value: e.value })
                  return formik.handleChange
                }}
                onBlur={formik.handleBlur}
                disabled={!uploadBusinessPlanState.businessPlanSession.year || uploadBusinessPlanState.isLoading || uploadBusinessPlanState.isUploading}
                className="w-full"
              />
              <label htmlFor="elementType">
                Type<span className="text-danger font-weight-bold text-large">&nbsp;*</span>
              </label>
            </div>
            {getFormErrorMessage("elementType")}
            <Divider type="solid" style={{ visibility: "hidden" }} />
          </div>

          <div className="p-fluid col-md-4">
            <div className="p-field p-float-label mt-4 mb-2 mr-4">
              <Dropdown
                id="revisionNumber"
                name="revisionNumber"
                value={formik.values.revisionNumber}
                options={uploadBusinessPlanState.possibleRevisionNumbers}
                optionValue="value"
                optionLabel="description"
                placeholder="Select revision number"
                onChange={e => {
                  formik.setFieldValue("revisionNumber", e.value)
                  return formik.handleChange
                }}
                onBlur={formik.handleBlur}
                disabled={!uploadBusinessPlanState.businessPlanSession.elementType || !uploadBusinessPlanState.possibleRevisionNumbers.length || uploadBusinessPlanState.isLoading || uploadBusinessPlanState.isUploading}
                className="w-full"
              />
              <label htmlFor="revisionNumber">
                Revision number<span className="text-danger font-weight-bold text-large">&nbsp;*</span>
              </label>
            </div>
            {getFormErrorMessage("revisionNumber")}
          </div>
          <div className="p-fluid col-md-12">
            <div className="p-field p-float-label">
              <Button type="button" style={{ width: "160px", bottom: "70px", float: "right" }} icon="pi pi-times" label="Reset all" className="p-button-danger ml-auto mr-4" onClick={() => uploadBusinessPlanDispatch({ type: ActionTypes.RESET_PARAMETERS })} />
            </div>
          </div>
          {uploadBusinessPlanState.enableFileSelect && !!!uploadBusinessPlanState.businessPlanSession.businessPlans.length ? (
            <div>
              <Divider type="solid" style={{ visibility: "hidden" }} />
              <FileUpload ref={fileUploadRef} auto={false} accept=".csv" maxFileSize={1000000} onUpload={onFileUpload} onSelect={onFileSelect} onError={onFileClear} onClear={onFileClear} headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate} chooseOptions={chooseOptions} />
            </div>
          ) : (
            <></>
          )}
        </form>
      </div>
    </>
  )
}

export default UploadDeasonalizedBusinessPlanForm
