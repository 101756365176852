import { useCallback } from "react"
import { BusinessPlan, SeasonalizedBusinessPlanInput } from "../types/BusinessPlan"

export default function useConvertSeasonalizedBusinessPlansToSeasonalizedBusinessPlanInput() {
    const convertSeasonalizedBusinessPlansToSeasonalizedBusinessPlanInput = useCallback((businessPlans: BusinessPlan[]): SeasonalizedBusinessPlanInput => {
        console.log(businessPlans)
        return {
            class: {
                error: null,
                name: businessPlans[0].projectClassName || businessPlans[0].spendClassName,
                value: null
            },
            jan: {
                error: null,
                value: businessPlans.filter(x => x.month === 1)[0].value
            },
            feb: {
                error: null,
                value: businessPlans.filter(x => x.month === 2)[0].value
            },
            mar: {
                error: null,
                value: businessPlans.filter(x => x.month === 3)[0].value
            },
            apr: {
                error: null,
                value: businessPlans.filter(x => x.month === 4)[0].value
            },
            may: {
                error: null,
                value: businessPlans.filter(x => x.month === 5)[0].value
            },
            jun: {
                error: null,
                value: businessPlans.filter(x => x.month === 6)[0].value
            },
            jul: {
                error: null,
                value: businessPlans.filter(x => x.month === 7)[0].value
            },
            aug: {
                error: null,
                value: businessPlans.filter(x => x.month === 8)[0].value
            },
            sep: {
                error: null,
                value: businessPlans.filter(x => x.month === 9)[0].value
            },
            oct: {
                error: null,
                value: businessPlans.filter(x => x.month === 10)[0].value
            },
            nov: {
                error: null,
                value: businessPlans.filter(x => x.month === 11)[0].value
            },
            dec: {
                error: null,
                value: businessPlans.filter(x => x.month === 12)[0].value
            },
            total: {
                error: null,
                value: businessPlans.reduce((accumulator, currentBusinessPlan) => accumulator + currentBusinessPlan.value,
                    0,)
            }
        }
    }, [])

    return convertSeasonalizedBusinessPlansToSeasonalizedBusinessPlanInput
}
