import { ElementType } from "./Element"

class Enums {
  static ElementType = {
    CAPEX: {
      value: 1,
      name: "CE",
      description: "Capital expenditure",
      subPath: "capital-and-exploratory"
    } as ElementType,

    OPEX: {
      value: 10,
      name: "Operational Expense",
      description: "Operational expense",
      subPath: "operational-expense"
    } as ElementType,

    CashFlow: {
      value: 20,
      name: "Cashflow",
      description: "Cashflow",
      subPath: "cashflows"
    } as ElementType,

    Earnings: {
      value: 30,
      name: "Earnings",
      description: "Earnings",
      subPath: "earnings"
    } as ElementType,

    Production: {
      value: 40,
      name: "Production",
      description: "Production",
      subPath: "production"
    } as ElementType
  }

  static ElementTypes = [
    this.ElementType.CAPEX,
    this.ElementType.OPEX,
    this.ElementType.CashFlow,
    this.ElementType.Earnings,
    this.ElementType.Production
  ] as ElementType[]

  static CustomElementType = [
    {
      value: 1,
      name: "Capital & Exploratory",
      description: "Capital expenditure",
      subPath: "capital-and-exploratory"
    },
    {
      value: 2,
      name: "Operational Expense",
      description: "Operational expense",
      subPath: "operational-expense"
    },
    {
      value: 3,
      name: "Earnings And Cashflow",
      description: "Cashflows & Earnings",
      subPath: "cashflows-earnings"
    },
    {
      value: 4,
      name: "Production",
      description: "Production",
      subPath: "production"
    }
  ] as ElementType[]

  static CapitalAndExploratoryDataSources = [
    {
      value: 1,
      description: "JDE"
    }
  ]

  static RevisionNumber = [
    {
      value: 0,
      description: "Base version"
    },
    {
      value: 1,
      description: "Revision 1"
    },
    {
      value: 2,
      description: "Revision 2"
    },
    {
      value: 3,
      description: "Revision 3"
    }
  ]

  static BusinessEntityType = [
    {
      value: 1,
      name: "Strategic Business Unit",
      orgChartColorClassName: "bg-indigo-600 text-white"
    },
    {
      value: 10,
      name: "Business Unit",
      orgChartColorClassName: "bg-teal-600 text-white"
    },
    {
      value: 20,
      name: "Hemi",
      orgChartColorClassName: "bg-purple-600 text-white"
    },
    {
      value: 30,
      name: "Asset",
      orgChartColorClassName: "bg-green-600 text-white"
    },
    // {
    //   value: 40,
    //   name: "Non-producing Asset",
    //   orgChartColorClassName: "bg-yellow-600 text-white"
    // },
    {
      value: 100,
      name: "Project",
      orgChartColorClassName: "bg-cyan-600 text-white"
    }
  ]

  static convertToEnum(enumType, value) {
    for (const enumProperty in enumType) {
      if (enumType[enumProperty].value === value) {
        return enumType[enumProperty]
      }
    }
    return null
  }
}
Object.freeze(Enums.ElementType)
Object.freeze(Enums.ElementTypes)
Object.freeze(Enums.BusinessEntityType)
Object.freeze(Enums.CapitalAndExploratoryDataSources)
Object.freeze(Enums.RevisionNumber)
Object.freeze(Enums)

export default Enums
