import { FC, useEffect, useState, useContext } from "react"

import { TreeTable } from "primereact/treetable"
import { Column } from "primereact/column"
import { TreeNode } from "primereact/treenode"
// import { MultiStateCheckbox } from 'primereact/multistatecheckbox';
import { Tag } from "primereact/tag"

import { InputSwitch, InputSwitchChangeEvent } from "primereact/inputswitch"

import { ManageEntityStateContext, ManageEntityDispatchContext } from "../../state/Context"
import useConvertEntityToPrimeReactTreeNode from "../../hooks/useConvertEntityToPrimeReactTreeNode"

import "./EntityList.scss"
import { Button } from "primereact/button"
import ActionType from "../../state/ActionTypes"

interface EntityListProps {}

const EntityList: FC<EntityListProps> = props => {
  const manageEntityDispatch = useContext(ManageEntityDispatchContext)
  const manageEntityState = useContext(ManageEntityStateContext)

  const [expandedKeys, setExpandedKeys] = useState(null)
  const [entitiesAsNodes, setEntitiesAsNodes] = useState<TreeNode[]>([])

  const convertEntityToNode = useConvertEntityToPrimeReactTreeNode()

  useEffect(() => {
    let nodes = [] as TreeNode[]
    setEntitiesAsNodes(null)
    manageEntityState.entities.forEach(entity => {
      nodes.push(convertEntityToNode(entity))
    })
    setEntitiesAsNodes(nodes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageEntityState.entities])

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">Entities</span>
      <div className="mb-4 gap-2">
        <InputSwitch checked={manageEntityState.includeActiveBusinessEntity} tooltip="toggle active" onChange={(e: InputSwitchChangeEvent) => manageEntityDispatch({ type: ActionType.TOGGLE_INCLUDE_ACTIVE_BUISNESS_ENTITIES })} className="mr-1 text-900" style={{ top: "9px" }} />
        <label htmlFor="input-rowclick" className="mr-3 text-700" style={{ fontSize: "15px", marginBottom: "11px" }}>
          {manageEntityState.includeActiveBusinessEntity ? <>include inactive entites</> : <>include active entites</>}
        </label>
        {/* <Button type="button" icon="pi pi-plus" label="Expand All" className='mr-2' onClick={expandAll} />
        <Button type="button" icon="pi pi-minus" label="Collapse All" className='mr-2' onClick={collapseAll} /> */}
        <Button
          icon="pi pi-refresh"
          tooltip="refresh"
          severity="warning"
          tooltipOptions={{ position: "left" }}
          className="mr-2"
          onClick={() => {
            manageEntityDispatch({ type: ActionType.RELOAD_OBJECTS })
            setExpandedKeys({})
          }}
          rounded
          raised
        />
        <Button
          tooltip="add entity"
          tooltipOptions={{ position: "left" }}
          onClick={() => {
            manageEntityDispatch({ type: ActionType.RESET_ADD_OR_EDIT_OBJECT })
            manageEntityDispatch({ type: ActionType.CHANGE_ADD_OR_EDIT_OBJECT_EDIT_MODE, value: false })
            manageEntityDispatch({ type: ActionType.TOGGLE_ADD_OR_EDIT_OBJECT_MODAL })
          }}
          icon="pi pi-plus"
          severity="info"
          rounded
          raised
          // className="addButton text-right"
        />
      </div>
    </div>
  )

  // const options = [
  //   { value: true, icon: 'pi pi-check', className: 'multi-select-active' },
  //   { value: false, icon: 'pi pi-times', className: 'multi-select-inactive' }
  // ];

  const statusCheckboxTemplate = node => {
    // return <MultiStateCheckbox value={node.data["isActive"]} options={options} optionValue="value" disabled />
    //return <Checkbox disabled checked={node.data["isActive"]} />
    return <Tag value={node.data.isActive ? "Active" : "Inactive"} severity={node.data.isActive ? "success" : "warning"}></Tag>
  }

  // const producingCheckboxTemplate = (node, column) => {
  //   if (!isSubEntity(node.data)) {
  //     return <MultiStateCheckbox value={node.data["isProducing"]} options={options} optionValue="value" disabled />
  //   }
  // }

  const listButtonStyle = {
    height: "2rem",
    width: "2rem"
  }

  const actionTemplate = node => {
    return (
      <div className="actions flex flex-wrap gap-2 float-end">
        <Button
          type="button"
          className="mr-2"
          icon="pi pi-briefcase"
          severity="help"
          style={{ ...listButtonStyle, fontSize: "0.5rem !important" }}
          tooltip="view family tree"
          tooltipOptions={{ position: "left" }}
          rounded
          onClick={() => {
            manageEntityDispatch({ type: ActionType.SET_OBJECT_FOR_ORG_CHART, value: node.data })
            manageEntityDispatch({ type: ActionType.TOGGLE_OBJECT_ORG_CHART_MODAL })
          }}
        ></Button>
        {node.data.isActive && (
          <Button
            type="button"
            className="mr-2"
            icon="pi pi-plus"
            severity="info"
            style={listButtonStyle}
            tooltip="Add sub entity"
            tooltipOptions={{ position: "left" }}
            rounded
            onClick={() => {
              manageEntityDispatch({ type: ActionType.RESET_ADD_OR_EDIT_OBJECT })
              manageEntityDispatch({ type: ActionType.SET_PARENT_ID_OF_ADD_OR_EDIT_OBJECT, parentEntityId: node.data.id })
              manageEntityDispatch({ type: ActionType.CHANGE_ADD_OR_EDIT_OBJECT_EDIT_MODE, value: false })
              manageEntityDispatch({ type: ActionType.TOGGLE_ADD_OR_EDIT_OBJECT_MODAL })
            }}
          ></Button>
        )}
        <Button
          type="button"
          className="mr-2"
          icon="pi pi-pencil"
          severity="secondary"
          style={{ ...listButtonStyle, fontSize: "0.5rem !important" }}
          tooltip="Edit"
          tooltipOptions={{ position: "left" }}
          onClick={() => {
            manageEntityDispatch({ type: ActionType.RESET_ADD_OR_EDIT_OBJECT })
            manageEntityDispatch({ type: ActionType.CHANGE_ADD_OR_EDIT_OBJECT_VALUES, value: node.data })
            manageEntityDispatch({ type: ActionType.SET_PARENT_ID_OF_ADD_OR_EDIT_OBJECT, parentEntityId: node.data.parentEntityId })
            manageEntityDispatch({ type: ActionType.CHANGE_ADD_OR_EDIT_OBJECT_EDIT_MODE, value: true })
            manageEntityDispatch({ type: ActionType.TOGGLE_ADD_OR_EDIT_OBJECT_MODAL })
          }}
          rounded
        ></Button>
        <Button
          type="button"
          icon={node.data.isActive ? "pi pi-times" : "pi pi-check"}
          style={listButtonStyle}
          tooltip={node.data.isActive ? "Deactivate" : "Activate"}
          tooltipOptions={{ position: "left" }}
          severity={node.data.isActive ? "danger" : "success"}
          rounded
          onClick={() => {
            manageEntityDispatch({ type: ActionType.SET_OBJECT_TO_CHANGE_STATUS, entity: node.data })
            manageEntityDispatch({ type: ActionType.SHOW_CHANGE_STATUS_DIALOG })
          }}
        ></Button>
      </div>
    )
  }

  return (
    <div className="EntityList mt-6">
      <TreeTable header={header} loading={manageEntityState.isLoading} rows={10} paginator rowsPerPageOptions={[10, 25, 50, 100]} value={entitiesAsNodes} tableStyle={{ minWidth: "50rem" }} expandedKeys={expandedKeys} onToggle={e => setExpandedKeys(e.value)} resizableColumns showGridlines>
        <Column field="name" header="Name" expander sortable style={{ width: "25%" }}></Column>
        <Column field="description" header="Description" sortable style={{ width: "27%" }}></Column>
        {/* <Column field="type" header="Type" body={producingCheckboxTemplate}></Column> */}
        <Column field="type" header="Type" style={{ width: "20%" }}></Column>
        <Column field="isActive" header="Status" body={statusCheckboxTemplate} style={{ width: "8%" }}></Column>
        <Column body={actionTemplate} style={{ width: "20%" }} />
      </TreeTable>
    </div>
  )
}
export default EntityList
