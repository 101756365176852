import { TreeNode } from "primereact/treenode"

import { BusinessEntity } from "../types/BusinessEntity"

// import useConvertSubEntityToPrimeReactTreeNode from "./useConvertSubEntityToPrimeReactTreeNode"
import useConvertObjectToPrimeTreeNode from "./useConvertObjectToPrimeTreeNode"
// import { groupBy } from "../helpers"

export default function useConvertEntityToPrimeReactTreeNode() {
  // const convertSubEntityToPrimeReactTreeNode = useConvertSubEntityToPrimeReactTreeNode()
  const convertObjectToPrimeTreeNode = useConvertObjectToPrimeTreeNode()

  const convertEntityToPrimeReactNode = (entity: BusinessEntity, convertElementsInsteadOfSubEntities: boolean = false, selectableCallback: any = null): TreeNode => {
    let node = {} as TreeNode
    node.id = node.key = entity.id
    node.label = entity.name
    node.data = entity
    node.selectable = true//selectableCallback ? selectableCallback(entity) : true
    //node.className = !entity.isActive ? "not-active" : ""
    node.leaf = !entity.subEntities.length
    if (convertElementsInsteadOfSubEntities) {
      if (entity.elements.length) {
        node.children = [] as TreeNode[]
        entity.elements.forEach(element => {
          node.children.push(convertObjectToPrimeTreeNode(element))
        })
      }
      // if (entity.elements.length) {
      //     let elementTypesMap = new Map<number, Element[]>()
      //     entity.elements.forEach(e => {
      //         if (elementTypesMap.has(e.elementType)) {
      //             elementTypesMap.get(e.elementType).push(e)
      //         }
      //         else {
      //             elementTypesMap.set(e.elementType, [e])
      //         }
      //     })
      //     node.children = [] as TreeNode[]
      //     for (let elementTypeKey of elementTypesMap.keys()) {
      //         const filteredElementType = elementTypes.filter(x => x.value === elementTypeKey)
      //         if (filteredElementType.length) {
      //             const elementType = filteredElementType[0]
      //             const elements = elementTypesMap.get(elementTypeKey)
      //             const elementTypeNode = {} as TreeNode
      //             elementTypeNode.id = elementTypeNode.key = elementType.value.toString()
      //             elementTypeNode.data = elementType
      //             elementTypeNode.children = [] as TreeNode[]
      //             elements.forEach(e => {
      //                 elementTypeNode.children.push(convertObjectToPrimeTreeNode(e))
      //             })
      //             node.children.push(elementTypeNode)
      //         }
      //     }
      // }

      // if (entity.elements.length) {
      //     const elementTypesMap = groupBy(entity.elements, 'elementType');
      //     node.children = Object.entries(elementTypesMap).reduce((accumulator, [key, elements]) => {
      //         const elementType = elementTypes.find(eType => eType.value === parseInt(key));

      //         if (elementType) {
      //             const elementTypeNode = {
      //                 id: key,
      //                 key: key,
      //                 data: elementType,
      //                 children: (elements as Element[]).map(e => convertObjectToPrimeTreeNode(e)),
      //             };
      //             accumulator.push(elementTypeNode);
      //         }
      //         return accumulator;
      //     }, []);
      // }
    } else {
      if (entity.subEntities.length) {
        node.children = [] as TreeNode[]
        entity.subEntities.forEach(subEntity => {
          node.children.push(convertEntityToPrimeReactNode(subEntity, false, selectableCallback))
        })
      }
    }
    return node
  }
  return convertEntityToPrimeReactNode
}
