import { SilentRequest } from "@azure/msal-browser"
import axios from "axios"
import { apiScope } from "../auth-config"
import { msalInstance } from "../index"
import { Toaster } from "../services/toaster.service"

const acquireAccessToken = async () => {
  const activeAccount = msalInstance.getActiveAccount()
  const accounts = msalInstance.getAllAccounts()

  const request: SilentRequest = {
    scopes: apiScope.scopes,
    account: activeAccount || accounts[0]
  }

  const authResult = await msalInstance.acquireTokenSilent(request)
  return authResult.accessToken
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

/* eslint-disable  @typescript-eslint/no-explicit-any */
function getErrorMessage(error: any) {
  let message = error.response?.data?.message

  if (message == null) {
    message = error.message
  }

  return message
}

axiosInstance.interceptors.request.use(
  async config => {
    if (config.method === "patch") {
      config.headers["Content-Type"] = "application/json-patch+json"
    }
    const accessToken = await acquireAccessToken()

    if (accessToken) {
      config.headers["Authorization"] = "Bearer " + accessToken
    }

    return config
  },
  error => {
    Toaster.showError(getErrorMessage(error))
    console.error(error)
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  async response => response,
  error => {
    Toaster.showError(getErrorMessage(error))
    console.error(error)
    return Promise.reject(error)
  }
)

export default axiosInstance
