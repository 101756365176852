import { FC, useContext, useEffect, useRef, useState } from "react"

import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';

import { InputText } from "primereact"
import { InputSwitch } from "primereact/inputswitch"
import { Dropdown } from 'primereact/dropdown';
import { Divider } from "primereact/divider"

import { ManageEntityDispatchContext, ManageEntityStateContext } from "../../state/Context"
import ActionTypes from "../../state/ActionTypes"

import "./AddOrEditEntityForm.scss"
import { Enums } from "../../types";
import useFlattenArray from "../../hooks/useFlattenArray";

interface AddOrEditEntityFormProps {
  formikRef: any
}

const AddOrEditEntityForm: FC<AddOrEditEntityFormProps> = props => {
  const manageEntityDispatch = useContext(ManageEntityDispatchContext)
  const manageEntityState = useContext(ManageEntityStateContext)
  const [businessEntityTypes, setBusinessEntityTypes] = useState([])
  const flattenArray = useFlattenArray()

  const nameRef = useRef(null)
  const formikRef = useRef(null);

  // const formikRef = useRef(null)

  useEffect(() => {
    if (manageEntityState.showAddOrEditEntityModal && nameRef) {
      nameRef.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageEntityState.showAddOrEditEntityModal])

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setSubmitting(manageEntityState.isSaving || false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageEntityState.isSaving])

  useEffect(() => {
    let minimumType = null
    let maximumTypeValue = null
    var allBusinessEntitties = flattenArray(manageEntityState.entities, "subEntities")
    if (manageEntityState.entityToAddOrEdit.parentEntityId) {
      const parentEntity = allBusinessEntitties.filter(x => x.id === manageEntityState.entityToAddOrEdit.parentEntityId)[0]
      minimumType = Enums.BusinessEntityType.filter(x => x.name === parentEntity.type)[0]
    }

    if (manageEntityState.entityToAddOrEdit.id) {
      const theEntity = allBusinessEntitties.filter(x => x.id === manageEntityState.entityToAddOrEdit.id)[0]
      if (theEntity.subEntities.length) {
        maximumTypeValue = Math.min(...theEntity.subEntities.map((x) => Enums.BusinessEntityType.filter(y => y.name === x.type)[0].value))
      }
    }

    if (minimumType && maximumTypeValue) {
      setBusinessEntityTypes(Enums.BusinessEntityType.filter(x => x.value >= minimumType.value && x.value <= maximumTypeValue))
    }
    else if (minimumType) {
      setBusinessEntityTypes(Enums.BusinessEntityType.filter(x => x.value >= minimumType.value))
    }
    else if (maximumTypeValue) {
      setBusinessEntityTypes(Enums.BusinessEntityType.filter(x => x.value <= maximumTypeValue))
    }
    else {
      setBusinessEntityTypes(Enums.BusinessEntityType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageEntityState.entityToAddOrEdit.parentEntityId])

  return (
    <Formik
      initialValues={{
        id: manageEntityState.entityToAddOrEdit.id || '',
        name: manageEntityState.entityToAddOrEdit.name || '',
        description: manageEntityState.entityToAddOrEdit.description || '',
        type: manageEntityState.entityToAddOrEdit.type || '',
        businessPlanDataSourceId: manageEntityState.entityToAddOrEdit.businessPlanDataSourceId || '',
        isActive: manageEntityState.entityToAddOrEdit.isActive || false
      }}
      validationSchema={Yup.object({
        name: Yup.string()
          .max(50, 'Must be 50 characters or less')
          .required('Required'),
        description: Yup.string()
          .max(250, 'Must be 250 characters or less')
          .required('Required'),
        type: Yup.string()
          .required('Required'),
        businessPlanDataSourceId: Yup.string()
          .max(100, 'Must be 100 characters or less')
          .required('Required'),
      })}
      onSubmit={(values, actions) => {
        formikRef.current = actions;
        manageEntityDispatch({
          type: ActionTypes.CHANGE_ADD_OR_EDIT_OBJECT_VALUES, value: values
        })
        manageEntityDispatch({ type: ActionTypes.INCREASE_SUBMIT_COUNT })
      }}>
      {formik => (
        <div className="AddOrEditEntityForm">
          <form ref={props.formikRef} onSubmit={formik.handleSubmit}>
            <div className="p-fluid">
              <div className="p-field p-float-label">
                <InputText id="name" name="name" disabled={manageEntityState.isSaving} {...formik.getFieldProps('name')} ref={nameRef} />
                <label htmlFor="name">Name<span className="text-danger font-weight-bold text-large">*</span></label>
              </div>
              <ErrorMessage name="name">
                {msg => <div style={{ color: 'red' }}>{msg}</div>}
              </ErrorMessage>
            </div>
            <Divider type="solid" style={{ visibility: "hidden" }} />
            <div className="p-fluid">
              <div className="p-field p-float-label">
                <InputText id="description" name="description" {...formik.getFieldProps('description')} />
                <label htmlFor="description">Description<span className="text-danger font-weight-bold text-large">*</span></label>
              </div>
              <ErrorMessage name="description">
                {msg => <div style={{ color: 'red' }}>{msg}</div>}
              </ErrorMessage>
            </div>
            <Divider type="solid" style={{ visibility: "hidden" }} />
            <div className="p-fluid">
              <div className="p-field p-float-label">
                <Dropdown id="type" name="type" options={businessEntityTypes} optionLabel="name" optionValue="name"  {...formik.getFieldProps('type')} />
                <label htmlFor="type">Type<span className="text-danger font-weight-bold text-large">*</span></label>
              </div>
              <ErrorMessage name="type">
                {msg => <div style={{ color: 'red' }}>{msg}</div>}
              </ErrorMessage>
            </div>
            <Divider type="solid" style={{ visibility: "hidden" }} />
            <div className="p-fluid">
              <div className="p-field p-float-label">
                <InputText id="businessPlanDataSourceId" name="businessPlanDataSourceId" {...formik.getFieldProps('businessPlanDataSourceId')} />
                <label htmlFor="businessPlanDataSourceId">MIDAS Id<span className="text-danger font-weight-bold text-large">*</span></label>
              </div>
              <ErrorMessage name="businessPlanDataSourceId">
                {msg => <div style={{ color: 'red' }}>{msg}</div>}
              </ErrorMessage>
            </div>
            <Divider type="solid" style={{ visibility: "hidden" }} />
            <div className="p-fluid">
              <div className="p-field p-float-label switch-container">
                <InputSwitch id="isAcive" name="isAcive" disabled={manageEntityState.isSaving}  {...formik.getFieldProps('isActive')} checked={formik.values.isActive} />
                <span className="font-weight-bold pl-2">{formik.values.isActive ? "Active" : "Inactive"}</span>
              </div>
            </div>
          </form >
        </div >
      )}
    </Formik>
  )
}

export default AddOrEditEntityForm
