export const groupBy = (array, key) => {
    return array.reduce((result, currentItem) => {
        (result[currentItem[key]] = result[currentItem[key]] || []).push(currentItem);
        return result;
    }, {});
}

export const flattenList = (arrayToFlatten: Array<any>, childrenPropertyName: string = "children"): Array<any> => {
    var flatArr = []
    for (var i = 0; i < arrayToFlatten.length; i++) {
        flatArr.push(arrayToFlatten[i]);
        if (arrayToFlatten[i][childrenPropertyName].length) {
            flatArr = flatArr.concat(flattenList(arrayToFlatten[i][childrenPropertyName], childrenPropertyName))
        }
    }
    return flatArr;
}