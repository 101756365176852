import React, { FC, useRef, useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"

import { useImmerReducer } from "use-immer"

import ActionTypes from "../../../state/ActionTypes"
import { UploadSeasonalizedBusinessPlanStateContext, UploadSeasonalizedBusinessPlanDispatchContext } from "../../../state/Context"
import { initialUploadSeasonalizedBusinessPlanState, uploadSeasonalizedBusinessPlanReducer } from "../../../state/UploadSeasonalizedBusinessPlan"

import { BusinessPlanSession } from "../../../types/BusinessPlanSession"
import { BusinessPanType, MIDASBusinessPlanInput } from "../../../types/BusinessPlan"
import { BusinessEntity, Enums } from "../../../types"

import useConvertElementTypeValueToString from "../../../hooks/useConvertElementTypeValueToString"
import useConvertVersionNumberToString from "../../../hooks/useConvertVersionNumberToString"
import useConvertRegularBusinessPlanToMIDASBusinessPlanInput from "../../../hooks/useConvertRegularBusinessPlanToMIDASBusinessPlanInput"
import useConvertSeasonalizedBusinessPlansToSeasonalizedBusinessPlanInput from "../../../hooks/useConvertSeasonalizedBusinessPlansToSeasonalizedBusinessPlanInput"

import { Message } from "primereact/message"
import { Dialog } from "primereact/dialog"
import { ConfirmDialog } from "primereact/confirmdialog"

import Page from "../../../components/Page"
import Loading from "../../../components/Loading"

import BusinessPlanService from "../../../services/api/businessPlan.service"
import BusinessEntityService from "../../../services/api/businessEntity.service"
import { Toaster } from "../../../services/toaster.service"

import "./UploadSeasonalizedBusinessPlan.scss"
import UploadSeasonalizedBusinessPlanForm from "../../../components/UploadSeasonalizedBusinessPlanForm"
import SpendClassService from "../../../services/api/spendClass.service"
import { SpendClass } from "../../../types/SpendClass"

import { Button } from "primereact/button"
import SeasonalizedBusinessPlanList from "../../../components/SeasonalizedBusinessPlanList"

interface UploadSeasonalizedBusinessPlanProps { }

const UploadSeasonalizedBusinessPlan: FC<UploadSeasonalizedBusinessPlanProps> = () => {
  const [state, dispatch] = useImmerReducer(uploadSeasonalizedBusinessPlanReducer, initialUploadSeasonalizedBusinessPlanState)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [displayEmptyUnSeasionalizedDialog, setDisplayEmptyUnSeasionalizedDialog] = useState<boolean>(false)
  const [loadProductionOptionDialog, setLoadProductionOptionDialog] = useState<boolean>(false)

  const formikRef = useRef<any>()
  const fileUploadRef = useRef<any>()

  const businessEntityService = useMemo(() => new BusinessEntityService(), [])
  const businessPlanService = useMemo(() => new BusinessPlanService(), [])
  const spendClassService = useMemo(() => new SpendClassService(), [])

  const convertElementTypeValueToString = useConvertElementTypeValueToString()
  const convertVersionNumberToString = useConvertVersionNumberToString()
  const convertRegularBusinessPlanToMIDASBusinessPlanInput = useConvertRegularBusinessPlanToMIDASBusinessPlanInput()
  const convertSeasonalizedBusinessPlansToSeasonalizedBusinessPlanInput = useConvertSeasonalizedBusinessPlansToSeasonalizedBusinessPlanInput()

  useEffect(() => {
    dispatch({ type: ActionTypes.START_LOADING })
    businessEntityService
      .getTopLevelBusinessEntities(false, true)
      .then(data => {
        if (data.totalCount) {
          dispatch({ type: ActionTypes.SET_BUSINESS_ENTITIES, value: data.items })
        }
      })
      .catch(() => { })
      .finally(() => dispatch({ type: ActionTypes.STOP_LOADING }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (state.businessPlanSession.elementType) {
      dispatch({ type: ActionTypes.START_LOADING })
      dispatch({ type: ActionTypes.SET_SPEND_CLASS_TYPES, value: [] as SpendClass[] })
      spendClassService
        .getSpendClassTypesForElementType(state.businessPlanSession.elementType)
        .then(result => {
          dispatch({ type: ActionTypes.SET_SPEND_CLASS_TYPES, value: result })
        })
        .catch(() => { })
        .finally(() => dispatch({ type: ActionTypes.STOP_LOADING }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.businessPlanSession.elementType])

  useEffect(() => {
    if (state.businessPlanSession.spendClassType) {
      dispatch({ type: ActionTypes.START_LOADING })
      const session = { ...state.businessPlanSession, id: null, businessPlans: [] }
      dispatch({ type: ActionTypes.SET_BUSINESS_PLAN_SESSION, value: session })
      dispatch({ type: ActionTypes.SET_DESEASONALIZED_BUSINESS_PLAN_SESSION, value: {} as BusinessPlanSession })
      dispatch({ type: ActionTypes.SET_PROJECTS, value: [] as BusinessEntity[] })
      const tasks = []
      tasks.push(businessPlanService.getLastLoadedBusinessPlanningSession(state.businessPlanSession.year, state.businessPlanSession.elementType, null, BusinessPanType.seasoanlized, state.businessEntityId, state.businessPlanSession.spendClassType.id))
      tasks.push(businessPlanService.getLastLoadedBusinessPlanningSession(state.businessPlanSession.year, state.businessPlanSession.elementType, null, BusinessPanType.normal, state.businessEntityId))
      if (state.businessPlanSession.elementType === Enums.ElementType.CAPEX.value) {
        tasks.push(businessEntityService.getProjectTreeMembers(state.businessEntityId, false))
      } else {
        tasks.push(spendClassService.getSpendClassesForSpendClassType(state.businessEntityId, state.businessPlanSession.spendClassType.id))
      }
      Promise.all(tasks)
        .then(results => {
          var sessionResult = results[0]
          if (!results[1] && state.businessPlanSession.elementType !== 4) {
            setDisplayEmptyUnSeasionalizedDialog(true)
            return
          }
          if (state.businessPlanSession.elementType === 4) {
            setLoadProductionOptionDialog(true)
          }
          if (sessionResult && sessionResult.businessPlans) {
            // Business plans come in a different format so we have to map to a view model
            let businessPlans = []
            let classNames = new Set(sessionResult.businessPlans.map(x => x.projectClassName || x.spendClassName))
            for (const className of classNames) {
              alert(className)
              businessPlans.push(convertSeasonalizedBusinessPlansToSeasonalizedBusinessPlanInput(sessionResult.businessPlans.filter(x => x.projectClassName === className || x.spendClassName === className)))
            }
            console.log(businessPlans)
            //const businessPlans = sessionResult.businessPlans.map(x => convertRegularBusinessPlanToMIDASBusinessPlanInput(x))
            const session = { ...state.businessPlanSession, id: sessionResult.id, businessPlans: businessPlans }
            dispatch({ type: ActionTypes.SET_BUSINESS_PLAN_SESSION, value: session })
            dispatch({ type: ActionTypes.DISABLE_FILE_SELECTION })
          }
          var deseasonalizedSessionResult = results[1]
          if (deseasonalizedSessionResult && deseasonalizedSessionResult.businessPlans.length) {
            const businessPlans = deseasonalizedSessionResult.businessPlans.map(x => convertRegularBusinessPlanToMIDASBusinessPlanInput(x))
            const deseasonalizedSession = { ...deseasonalizedSessionResult, businessPlans }
            dispatch({ type: ActionTypes.SET_DESEASONALIZED_BUSINESS_PLAN_SESSION, value: deseasonalizedSession })
          }
          if (state.businessPlanSession.elementType === Enums.ElementType.CAPEX.value) {
            dispatch({ type: ActionTypes.SET_PROJECTS, value: results[2] || ([] as BusinessEntity[]) })
          } else {
            dispatch({ type: ActionTypes.SET_SPEND_CLASSES, value: results[2] || ([] as SpendClass[]) })
          }
          //TODO: Show dialog when no deseasonalized data
        })
        .catch((error) => { console.log(error) })
        .finally(() => dispatch({ type: ActionTypes.STOP_LOADING }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.businessPlanSession.spendClassType])

  useEffect(() => {
    if (!(state.businessPlanSession.spendClassType && state.deseasonalizedusinessPlanSession.id && state.deseasonalizedusinessPlanSession.businessPlans.length)) {
      dispatch({ type: ActionTypes.DISABLE_FILE_SELECTION })
      dispatch({ type: ActionTypes.SET_BUSINESS_PLANS, value: [] as MIDASBusinessPlanInput[] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.businessPlanSession.spendClassType, state.deseasonalizedusinessPlanSession.id, state.deseasonalizedusinessPlanSession.businessPlans])

  useEffect(() => {
    if (state.businessPlanSession.spendClassType && state.deseasonalizedusinessPlanSession.id && state.deseasonalizedusinessPlanSession.businessPlans.length && !state.isLoading && !state.businessPlanSession.businessPlans.length) {
      dispatch({ type: ActionTypes.ENABLE_FILE_SELECTION })
      //dispatch({ type: ActionTypes.IS_UPLOAD_DISABLED, value: false })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.businessPlanSession.spendClassType, state.deseasonalizedusinessPlanSession.id, state.deseasonalizedusinessPlanSession.businessPlans, state.isLoading, state.businessPlanSession.businessPlans])

  function hasErrors(businessPlan) {
    return Object.keys(businessPlan).some(key => businessPlan[key].error)
  }

  useEffect(() => {
    if (!state.businessPlanSession.id && state.businessPlanSession.businessPlans.length && !state.businessPlanSession.businessPlans.some(hasErrors)) {
      dispatch({ type: ActionTypes.ENABLE_FILE_UPLOAD })
    } else {
      dispatch({ type: ActionTypes.DISABLE_FILE_UPLOAD })
      if (state.businessPlanSession.businessPlans.some(hasErrors)) {
        setErrorMessage("The file you're attempting to upload contains errors. Please correct these errors and try uploading again.")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.businessPlanSession.id, state.businessPlanSession.businessPlans])

  useEffect(() => {
    if (state.uploadCount) {
      dispatch({ type: ActionTypes.START_LOADING })
      businessPlanService
        .saveSeasonalizedBusinessPlanSession(state.businessEntityId, state.deseasonalizedusinessPlanSession.id, state.businessPlanSession)
        .then(result => {
          Toaster.showSuccess("Business plans uploaded successfully")
          dispatch({
            type: ActionTypes.SET_BUSINESS_PLAN_SESSION,
            value: {
              id: null,
              year: null,
              elementType: null,
              revisionNumber: null,
              businessPlans: [] as MIDASBusinessPlanInput[],
              totalValue: 0
            } as BusinessPlanSession
          })
          dispatch({ type: ActionTypes.RESET_PARAMETERS })
        })
        .catch(() => { })
        .finally(() => dispatch({ type: ActionTypes.STOP_LOADING }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.uploadCount])

  useEffect(() => {
    if (state.deleteBusinessPlanSessionCount && state.businessPlanSession.id) {
      dispatch({ type: ActionTypes.START_LOADING })
      businessPlanService
        .deleteBusinessPlanSession(state.businessPlanSession.id)
        .then(result => {
          Toaster.showSuccess("Business plans deleted successfully")
          if (result) {
            dispatch({ type: ActionTypes.UPDATE_PARAMETER, parameter: "id", value: null })
            dispatch({ type: ActionTypes.SET_BUSINESS_PLANS, value: [] as MIDASBusinessPlanInput[] })
            // dispatch({ type: ActionTypes.IS_UPLOAD_DISABLED, value: false })
            let revisionNumbers = [...state.possibleRevisionNumbers]
            const indexOfDeletedRevisionNumber = revisionNumbers.indexOf(state.possibleRevisionNumbers.filter(x => x.value === state.businessPlanSession.revisionNumber)[0])
            console.log(indexOfDeletedRevisionNumber)
            revisionNumbers.splice(indexOfDeletedRevisionNumber + 1)
            dispatch({ type: ActionTypes.SET_POSSIBLE_REVISON_NUMBERS, value: revisionNumbers })
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => dispatch({ type: ActionTypes.STOP_LOADING }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.deleteBusinessPlanSessionCount])
  const hideEmptyUnSeasionalizedDialog = () => {
    setDisplayEmptyUnSeasionalizedDialog(false)
  }
  const DisplayEmptyUnSeasionalizedDialogFooter = (
    <React.Fragment>
      <Link to="/admin/businessplan/load/deseasonalized">
        <Button label="initiate deseasonalized upload." severity="info" />
      </Link>
    </React.Fragment>
  )

  const SelectProductionSourceTemplate = (
    <div className="flex flex-wrap gap-2 justify-center items-center">
      <Button label="From MIDAS source." severity="info" size="small" />
      <Button label="From RAPID template." severity="success" size="small" />
    </div>
  )

  return (
    <Page title="Upload deasonalized business plan data">
      <UploadSeasonalizedBusinessPlanStateContext.Provider value={state}>
        <UploadSeasonalizedBusinessPlanDispatchContext.Provider value={dispatch}>
          <Loading isLoading={state.isLoading} />
          <div className="card flex flex-wrap justify-content-center gap-3">
            <ConfirmDialog
              visible={state.showDeleteBusinessPlanSessionModal}
              onHide={() => dispatch({ type: ActionTypes.HIDE_DELETE_BUSINESS_PLAN_SESSION_MODAL })}
              message={state.showDeleteBusinessPlanSessionModal ? `Are you sure you want to delete existing ${convertElementTypeValueToString(state.businessPlanSession.elementType)} business plan data for ${state.businessPlanSession.year}, ${convertVersionNumberToString(state.businessPlanSession.revisionNumber)}` : ""}
              header="Delete existing business plan data?"
              icon="pi pi-exclamation-triangle"
              accept={() => {
                dispatch({ type: ActionTypes.DELETE_BUSINESS_PLAN_SESSION })
              }}
            />
            <Dialog header="Error" visible={!!errorMessage} style={{ width: "50vw" }} onHide={() => setErrorMessage("")}>
              <p className="m-0">{errorMessage}</p>
            </Dialog>

            <Dialog closable={false} visible={displayEmptyUnSeasionalizedDialog} style={{ width: "32rem" }} breakpoints={{ "960px": "75vw", "641px": "90vw" }} header="Message" modal footer={DisplayEmptyUnSeasionalizedDialogFooter} onHide={hideEmptyUnSeasionalizedDialog}>
              <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                <span className="text text-900 font-bold">Deseasonalized data for this business entity has not been uploaded. Please click the button below to initiate the upload.</span>
              </div>
            </Dialog>

            <Dialog closable={false} visible={loadProductionOptionDialog} style={{ width: "32rem" }} breakpoints={{ "960px": "75vw", "641px": "90vw" }} header="Message" modal footer={<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{SelectProductionSourceTemplate}</div>} onHide={() => setLoadProductionOptionDialog(false)}>
              <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                <span className="text text-900 font-bold">Where would you prefer to obtain your data source?</span>
              </div>
            </Dialog>

            <div className="card flex justify-content-center"></div>

            <div className="UploadBusinessPlan">
              <UploadSeasonalizedBusinessPlanForm formikRef={formikRef} fileUploadRef={fileUploadRef} />
            </div>

            <div style={{ boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.3)", marginTop: "20px" }}>
              {!!state.businessPlanSession.businessPlans.length && (
                <>
                  {state.businessPlanSession.id && <Message severity="error" text={`Seasonalized ${convertElementTypeValueToString(state.businessPlanSession.elementType)} business plan data already exists for ${state.businessPlanSession.year}`} style={{ width: "100%" }} />}
                  <SeasonalizedBusinessPlanList />
                  {/* <BusinessPlans /> */}
                </>
              )}
            </div>
          </div>
        </UploadSeasonalizedBusinessPlanDispatchContext.Provider>
      </UploadSeasonalizedBusinessPlanStateContext.Provider>
    </Page>
  )
}

export default UploadSeasonalizedBusinessPlan
