import { FC, useEffect, useState, useContext } from "react"

import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"
import { Tooltip } from "primereact/tooltip"
import { Toolbar } from "primereact/toolbar"

import { UploadDeseasonalizedBusinessPlanStateContext, UploadDeseasonalizedBusinessPlanDispatchContext } from "../../state/Context"

import ActionTypes from "../../state/ActionTypes"

import useConvertElementTypeValueToString from "../../hooks/useConvertElementTypeValueToString"

import "./BusinessPlanList.scss"
import { Enums } from "../../types"
import { MIDASBusinessPlanInput } from "../../types/BusinessPlan"

interface BusinessPlanProps { }

const ElementList: FC<BusinessPlanProps> = props => {
  const uploadBusinessPlanState = useContext(UploadDeseasonalizedBusinessPlanStateContext)
  const uploadBusinessPlanDispatch = useContext(UploadDeseasonalizedBusinessPlanDispatchContext)
  const [entityHeader, setEntityHeader] = useState("")

  const convertElementTypeValueToString = useConvertElementTypeValueToString()

  useEffect(() => {
    let header = uploadBusinessPlanState.businessPlanSession.elementType === Enums.ElementType.CAPEX.value ? "Project" : "Entity"
    setEntityHeader(header)
  }, [uploadBusinessPlanState.businessPlanSession.elementType])

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">{uploadBusinessPlanState.businessPlanSession.year && uploadBusinessPlanState.businessPlanSession.elementType ? `${uploadBusinessPlanState.businessPlanSession.year} Business plan - ${convertElementTypeValueToString(uploadBusinessPlanState.businessPlanSession.elementType)}` : "Business plan"}</span>
      <div className="flex align-items-center justify-content-end">
        {uploadBusinessPlanState.businessPlanSession.id && (
          <Button
            icon="pi pi-trash"
            severity="danger"
            onClick={() => {
              uploadBusinessPlanDispatch({ type: ActionTypes.SHOW_DELETE_BUSINESS_PLAN_SESSION_MODAL })
            }}
          />
        )}
        {uploadBusinessPlanState.enableFileSelect ? (
          <>
            <Button
              className="ml-4 mr-4 p-danger"
              icon="pi pi-times"
              severity="danger"
              label="Cancel Upload"
              onClick={() => {
                uploadBusinessPlanDispatch({ type: ActionTypes.SET_BUSINESS_PLANS, value: [] as MIDASBusinessPlanInput[] })
              }}
            />
            <Button
              className="ml-4 mr-4"
              style={{ boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.3)" }}
              icon="pi pi-upload"
              label="Complete Upload"
              disabled={!uploadBusinessPlanState.enableFileUpload}
              onClick={() => {
                uploadBusinessPlanDispatch({ type: ActionTypes.UPLOAD_FILE })
              }}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  )

  const entityNameTemplate = (businessPlan, props) => {
    const id = `businessEntityName${props.rowIndex}`
    return (
      <>
        {businessPlan.businessEntityName.error && <Tooltip target={`#${id}`} position="right" />}
        <span id={id} className={`font-weight-bold ${businessPlan.businessEntityName.error ? "text-danger" : ""}`} data-pr-tooltip={businessPlan.businessEntityName.error}>
          {businessPlan.businessEntityName.value}
        </span>
      </>
    )
  }

  const valueTemplate = (businessPlan, props) => {
    const id = `value_${props.rowIndex}`
    return (
      <>
        {businessPlan.value.error && <Tooltip target={`#${id}`} position="right" />}
        <span id={id} className={`font-weight-bold ${businessPlan.value.error ? "text-danger" : ""}`} data-pr-tooltip={businessPlan.value.error}>
          ${businessPlan.value.value.toLocaleString(undefined, { minimumFractionDigits: 5 })}
        </span>
      </>
    )
  }

  return (
    <div className="ElementList">
      {/* <Toolbar className="mb-4" right={}></Toolbar> */}
      <DataTable header={header} loading={uploadBusinessPlanState.isLoading} rows={10} paginator rowsPerPageOptions={[10, 25, 50, 100]} value={uploadBusinessPlanState.businessPlanSession.businessPlans} tableStyle={{ minWidth: "50rem" }}>
        <Column field="businessEntityName.value" header={entityHeader} body={entityNameTemplate} sortable></Column>
        <Column field="value" header="value" body={valueTemplate}></Column>
      </DataTable>
    </div>
  )
}
export default ElementList
