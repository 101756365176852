import { Enums } from "../types"
import { BusinessEntity } from "../types/BusinessEntity"
import { TreeNode } from "primereact/treenode"

export default function useConvertEntityToOrgChartNode() {
    const convertEntityToOrgChartNode = (entity: BusinessEntity): TreeNode => {
        // const classNames = [
        //     {
        //         name: "Strategic Business Unit",
        //         value: "bg-indigo-600 text-white"
        //     },
        //     {
        //         name: "Business Unit",
        //         value: "bg-teal-600 text-white"
        //     },
        //     {
        //         name: "Hemi",
        //         value: "bg-purple-600 text-white"
        //     },
        //     {
        //         name: "Producing Asset",
        //         value: "bg-green-600 text-white"
        //     },
        //     {
        //         name: "Non-producing Asset",
        //         value: "bg-yellow-600 text-white"
        //     }]
        let node = {} as TreeNode
        node.label = entity.name
        node.leaf = !entity.subEntities.length
        node.key = node.id = entity.id
        node.data = entity
        var theClass = Enums.BusinessEntityType.filter(x => x.name === entity.type)
        if (theClass.length) {
            node.className = theClass[0].orgChartColorClassName
        }
        else {
            node.className = "bg-red-600 text-white"
        }
        node.style = { borderRadius: '12px' }
        node.expanded = !!(entity.subEntities && entity.subEntities.length)
        if (entity.subEntities && entity.subEntities.length) {
            node.children = [] as TreeNode[]
            entity.subEntities.forEach(subEntity => {
                node.children.push(convertEntityToOrgChartNode(subEntity))
            })
        }
        return node
    }
    return convertEntityToOrgChartNode
}

