import { useCallback } from "react"
import { TreeNode } from "primereact/treenode"

export default function useConvertObjectToPrimeTreeNode() {
    const convertObjectToPrimeTreeNode = useCallback((object: any): TreeNode => {
        let node = {} as TreeNode
        node.id = node.key = object.id.toString()
        node.data = object
        node.className = "isActive" in object ? (!object.isActive ? "not-active" : "") : ""
        node.leaf = object.children ? object.children.length : true
        return node
    }, [])

    return convertObjectToPrimeTreeNode
}
