import { BusinessEntity } from "../../types/BusinessEntity"
import ActionType from "../ActionTypes"
import { flattenList } from "../../helpers"

export interface ManageEntityState {
  entities: BusinessEntity[]
  loadCount: number
  isLoading: boolean
  entityToAddOrEdit: BusinessEntity | null
  showAddOrEditEntityModal: boolean
  addOrEntityModalTitle: string
  entityToChangeStatus: BusinessEntity | null
  showChangeEntityStatusModal: boolean
  changeStatusCount: number
  allowSubmit: boolean
  showEntityOrgChart: boolean
  entityToDisplayOrgChartFor: BusinessEntity | null
  entityOrgChartTitle: string
  isEdit: boolean | null
  saveCount: number
  isSaving: boolean | null
  includeActiveBusinessEntity: boolean | null
}

export const initialManageEntityState: ManageEntityState = {
  entities: [] as BusinessEntity[],
  loadCount: 0,
  isLoading: false,
  entityToAddOrEdit: {} as BusinessEntity,
  showAddOrEditEntityModal: false,
  addOrEntityModalTitle: "Add entity",
  entityToChangeStatus: {} as BusinessEntity,
  showChangeEntityStatusModal: false,
  changeStatusCount: 0,
  allowSubmit: true,
  showEntityOrgChart: false,
  entityToDisplayOrgChartFor: {} as BusinessEntity,
  entityOrgChartTitle: "",
  isEdit: null,
  saveCount: 0,
  isSaving: null,
  includeActiveBusinessEntity: true
}

export function manageEntityReducer(draft: any, action: any) {
  switch (action.type) {
    case ActionType.RELOAD_OBJECTS:
      draft.loadCount++
      return
    case ActionType.START_LOADING:
      draft.isLoading = true
      return
    case ActionType.STOP_LOADING:
      draft.isLoading = false
      return
    case ActionType.CLEAR_OBJECT_LIST:
      draft.entities = [] as BusinessEntity[]
      return
    case ActionType.SET_OBJECT_LIST:
      draft.entities = action.value.businessEntities
      return
    case ActionType.ADD_NEW_OBJECT_TO_OBJECT_LIST:
      draft.entities.unshift(action.value)
      return
    case ActionType.ADD_CHILD_OBJECTS_TO_OBJECT:
      let flattenedEntities = flattenList(draft.entities, "subEntities")
      console.log(flattenedEntities)
      let filteredEntities = flattenedEntities.filter(x => x.id === action.value.parentEntityId)
      if (filteredEntities.length) {
        const parentEntity = filteredEntities[0]
        action.value.subEntities.forEach(subEntity => {
          parentEntity.subEntities.push(subEntity)
        })
      }
      return
    // let matched = draft.entities.filter(e => e.id === action.value.entityId)
    // if (matched.length) {
    //     action.value.subEntities.forEach(x => {
    //         matched[0].subEntities.push(x)
    //     })
    // }
    // return
    case ActionType.RESET_ADD_OR_EDIT_OBJECT:
      var entityToAddOrEdit = {
        id: null,
        name: null,
        description: null,
        parentEntityId: null,
        type: null,
        businessPlanDataSourceId: null,
        isActive: true
      }
      draft.entityToAddOrEdit = entityToAddOrEdit as BusinessEntity
      draft.entityToAddOrEdit.subEntities = [] as BusinessEntity[]
      // if (!action.isSubEntity) {
      //     draft.entityToAddOrEdit = entityToAddOrEdit as BusinessEntity
      //     draft.entityToAddOrEdit.isProducing = false
      //     draft.entityToAddOrEdit.subEntities = [] as SubEntity[]
      // }
      // else {
      //     draft.entityToAddOrEdit = entityToAddOrEdit as SubEntity
      //     draft.entityToAddOrEdit.entityId = null
      // }
      draft.isEdit = null
      return
    case ActionType.CHANGE_ADD_OR_EDIT_MODAL_TITLE:
      draft.addOrEntityModalTitle = action.value
      return
    case ActionType.CHANGE_ADD_OR_EDIT_OBJECT_EDIT_MODE:
      draft.isEdit = action.value
      return
    case ActionType.TOGGLE_ADD_OR_EDIT_OBJECT_MODAL:
      draft.showAddOrEditEntityModal = !draft.showAddOrEditEntityModal
      return
    case ActionType.CHANGE_ADD_OR_EDIT_OBJECT_VALUES:
      draft.entityToAddOrEdit.id = action.value.id
      draft.entityToAddOrEdit.name = action.value.name
      draft.entityToAddOrEdit.description = action.value.description
      draft.entityToAddOrEdit.type = action.value.type
      draft.entityToAddOrEdit.businessPlanDataSourceId = action.value.businessPlanDataSourceId
      draft.entityToAddOrEdit.isActive = action.value.isActive
      return
    case ActionType.SET_PARENT_ID_OF_ADD_OR_EDIT_OBJECT:
      draft.entityToAddOrEdit.parentEntityId = action.parentEntityId
      return
    case ActionType.ENABLE_SUBMIT:
      draft.allowSubmit = true
      return
    case ActionType.DISABLE_SUBMIT:
      draft.allowSubmit = false
      return
    case ActionType.INCREASE_SUBMIT_COUNT:
      draft.saveCount++
      return
    case ActionType.SET_SAVING:
      draft.isSaving = action.value
      return
    case ActionType.UPDATE_EDITED_OBJECT:
      const flattenedEntities2 = flattenList(draft.entities, "subEntities")
      const filteredEntities2 = flattenedEntities2.filter(x => x.id === action.entity.id)
      if (filteredEntities2.length) {
        const matchedEntityToUpdate = filteredEntities2[0]
        matchedEntityToUpdate.id = action.entity.id
        matchedEntityToUpdate.name = action.entity.name
        matchedEntityToUpdate.description = action.entity.description
        matchedEntityToUpdate.type = action.entity.type
        matchedEntityToUpdate.businessPlanDataSourceId = action.entity.businessPlanDataSourceId
        matchedEntityToUpdate.parentEntityId = action.entity.parentEntityId
        matchedEntityToUpdate.isActive = action.entity.isActive
        matchedEntityToUpdate.subEntities = action.entity.subEntities
      }
      // let matchedEntityToUpdate = draft.entities.filter(e => e.id === action.entity.id)
      // if (matchedEntityToUpdate.length) {
      //     matchedEntityToUpdate[0].id = action.entity.id
      //     matchedEntityToUpdate[0].name = action.entity.name
      //     matchedEntityToUpdate[0].description = action.entity.description
      //     matchedEntityToUpdate[0].type = action.entity.type
      //     matchedEntityToUpdate[0].parentEntityId = action.entity.parentEntityId
      //     matchedEntityToUpdate[0].isActive = action.entity.isActive
      //     matchedEntityToUpdate[0].subEntities = action.entity.subEntities
      // }
      return
    case ActionType.UPDATE_EDITED_SUB_OBJECT:
      let matchedEntityToUpdateSubEntity = draft.entities.filter(e => e.id === action.subEntity.entityId)
      if (matchedEntityToUpdateSubEntity.length) {
        let matchedSubEntity = matchedEntityToUpdateSubEntity[0].subEntities.filter(e => e.id === action.subEntity.id)
        if (matchedSubEntity.length) {
          matchedSubEntity[0].id = action.subEntity.id
          matchedSubEntity[0].name = action.subEntity.name
          matchedSubEntity[0].description = action.subEntity.description
          matchedSubEntity[0].type = action.subEntity.type
          matchedSubEntity[0].businessPlanDataSourceId = action.subEntity.businessPlanDataSourceId
          matchedSubEntity[0].parentEntityId = action.subEntity.parentEntityId
          matchedSubEntity[0].isActive = action.subEntity.isActive
        }
      }
      return
    case ActionType.SET_OBJECT_TO_CHANGE_STATUS:
      draft.entityToChangeStatus = action.entity
      return
    case ActionType.SHOW_CHANGE_STATUS_DIALOG:
      draft.showChangeEntityStatusModal = true
      return
    case ActionType.HIDE_CHANGE_STATUS_DIALOG:
      draft.showChangeEntityStatusModal = false
      return
    case ActionType.INCREASE_CHANGE_STATUS_COUNT:
      draft.changeStatusCount++
      return
    case ActionType.SET_OBJECT_FOR_ORG_CHART:
      draft.entityOrgChartTitle = `Org chart for ${action.value.description}`
      draft.entityToDisplayOrgChartFor = action.value
      return
    case ActionType.TOGGLE_OBJECT_ORG_CHART_MODAL:
      draft.showEntityOrgChart = !draft.showEntityOrgChart
      return
    case ActionType.TOGGLE_INCLUDE_ACTIVE_BUISNESS_ENTITIES:
      draft.includeActiveBusinessEntity = !draft.includeActiveBusinessEntity
      return
  }
}
