import { FC, useEffect, useState, useCallback } from 'react';

import { OrganizationChart, OrganizationChartNodeData } from 'primereact/organizationchart';


import { BusinessEntity } from '../../types/BusinessEntity';
import useConvertEntityToOrgChartNode from "../../hooks/useConvertEntityToOrgChartNode"

import './EntityOrganizationalChart.scss';

interface EntityOrganizationalChartProps {
  entity: BusinessEntity
}

const EntityOrganizationalChart: FC<EntityOrganizationalChartProps> = (props) => {

  const convertEntityToOrgChartNode = useCallback(useConvertEntityToOrgChartNode(), [])
  const [chart, setChart] = useState<OrganizationChartNodeData[]>(null)

  useEffect(() => {
    if (props.entity) {
      setChart([convertEntityToOrgChartNode(props.entity)])
    }
    else {
      setChart(null)
    }
  }, [props.entity, convertEntityToOrgChartNode])

  return (
    <div className="EntityOrganizationalChart">
      {chart && <OrganizationChart value={chart}></OrganizationChart>}
    </div>
  )
}

export default EntityOrganizationalChart;
