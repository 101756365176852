import React, { useEffect } from "react"
import "./Page.scss"

interface PageProp {
  title: string
  children: React.ReactNode
}

const Page: React.FC<PageProp> = (props: PageProp) => {
  useEffect(() => {
    document.title = `${props.title} | RAPID`
    window.scrollTo(0, 0)
  }, [props.title])

  return <>{props.children}</>
}

export default Page
