import { BusinessEntity } from "../../types"
import { BusinessPlan } from "../../types/BusinessPlan"
import { BusinessPlanSession } from "../../types/BusinessPlanSession"
import { SpendClass, SpendClassType } from "../../types/SpendClass"
import ActionTypes from "../ActionTypes"

export interface UploadSeasonalizedBusinessPlanState {
  isLoading: boolean
  businessEntityId?: string
  businessPlanSession: BusinessPlanSession
  deseasonalizedusinessPlanSession: BusinessPlanSession
  enableFileSelect: boolean
  enableFileUpload: boolean
  showDeleteBusinessPlanSessionModal: boolean
  deleteBusinessPlanSessionCount: number
  businessEntities: BusinessEntity[]
  projects: BusinessEntity[]
  spendClassTypes: SpendClassType[]
  spendClasses: SpendClass[]
  uploadCount: number
  resetParameterCount: number
  uploadPreventionErrorExists: boolean
  possibleRevisionNumbers: { value: number; description: string }[]


  fileToUpload: File | null
  fileUploaded: boolean
  enableUploadButtonByFileUpload: boolean
  loadCount: number
  isUploading: boolean | null
  isUploadDisabled: boolean
  exception: boolean
}

export const initialUploadSeasonalizedBusinessPlanState: UploadSeasonalizedBusinessPlanState = {
  isLoading: false,
  // year: null,
  // elementType: null,
  // revisionNumber: null,
  businessPlanSession: {
    id: null,
    elementType: null,
    year: null,
    revisionNumber: null,
    businessPlans: [],
    totalValue: 0
  } as BusinessPlanSession,
  deseasonalizedusinessPlanSession: {
    id: null,
    elementType: null,
    year: null,
    revisionNumber: null,
    businessPlans: [],
    totalValue: 0
  } as BusinessPlanSession,
  enableFileSelect: false,
  enableFileUpload: false,
  showDeleteBusinessPlanSessionModal: false,
  deleteBusinessPlanSessionCount: 0,
  businessEntities: [] as BusinessEntity[],
  projects: [] as BusinessEntity[],
  spendClassTypes: [] as SpendClassType[],
  spendClasses: [] as SpendClass[],
  uploadCount: 0,
  resetParameterCount: 0,
  uploadPreventionErrorExists: false,
  possibleRevisionNumbers: [] as { value: number; description: string }[],

  fileToUpload: null,
  fileUploaded: false,
  loadCount: 0,
  isUploading: false,
  exception: false,
  enableUploadButtonByFileUpload: false,
  isUploadDisabled: false,

}

export function uploadSeasonalizedBusinessPlanReducer(draft: any, action: any) {
  switch (action.type) {
    case ActionTypes.START_LOADING:
      draft.isLoading = true
      return
    case ActionTypes.STOP_LOADING:
      draft.isLoading = false
      return
    case ActionTypes.UPDATE_PARAMETER:
      draft.businessPlanSession[action.parameter] = action.value
      return
    case ActionTypes.SET_ERROR_EXISTS:
      draft.uploadPreventionErrorExists = action.value
      return
    case ActionTypes.SET_BUSINESS_ENTITY_ID:
      draft.businessEntityId = action.value
      return
    case ActionTypes.SET_BUSINESS_ENTITIES:
      draft.businessEntities = action.value
      return
    case ActionTypes.SET_PROJECTS:
      draft.projects = action.value
      return
    case ActionTypes.SET_SPEND_CLASS_TYPES:
      draft.spendClassTypes = action.value
      return
    case ActionTypes.SET_SPEND_CLASSES:
      draft.spendClasses = action.value
      return
    case ActionTypes.SET_BUSINESS_PLAN_SESSION:
      draft.businessPlanSession = action.value
      return
    case ActionTypes.SET_DESEASONALIZED_BUSINESS_PLAN_SESSION:
      draft.deseasonalizedusinessPlanSession = action.value
      return
    case ActionTypes.SET_BUSINESS_PLANS:
      draft.businessPlanSession.businessPlans = action.value
      return
    case ActionTypes.ENABLE_FILE_SELECTION:
      draft.enableFileSelect = true
      return
    case ActionTypes.DISABLE_FILE_SELECTION:
      draft.enableFileSelect = false
      return
    case ActionTypes.ENABLE_FILE_UPLOAD:
      draft.enableFileUpload = true
      return
    case ActionTypes.DISABLE_FILE_UPLOAD:
      draft.enableFileUpload = false
      return
    case ActionTypes.SHOW_DELETE_BUSINESS_PLAN_SESSION_MODAL:
      draft.showDeleteBusinessPlanSessionModal = true
      return
    case ActionTypes.HIDE_DELETE_BUSINESS_PLAN_SESSION_MODAL:
      draft.showDeleteBusinessPlanSessionModal = false
      return
    case ActionTypes.DELETE_BUSINESS_PLAN_SESSION:
      draft.deleteBusinessPlanSessionCount++
      return
    // case ActionTypes.SET_BUSINESS_PLANS_TO_UPLOAD:
    //   draft.businessPlansToUpload = action.value || [] as MIDASBusinessPlanInput[]
    //   return
    case ActionTypes.UPLOAD_FILE:
      draft.uploadCount++
      return
    case ActionTypes.RESET_PARAMETERS:
      draft.resetParameterCount++
      return
    case ActionTypes.CLEAR_OBJECT_LIST:
      draft.loadedBusinessPlans = [] as BusinessPlan[]
      return
    case ActionTypes.SET_OBJECT_LIST:
      draft.loadedBusinessPlans = action.value
      return
    case ActionTypes.INCREASE_SUBMIT_COUNT:
      draft.uploadCount++
      return
    case ActionTypes.FILE_UPLOADED:
      draft.fileUploaded = true
      return
    case ActionTypes.FILE_INPUT_EMPTY:
      draft.fileUploaded = false
      return
    case ActionTypes.ENABLE_EXECEPTION_OCCURED:
      draft.exception = true
      return
    case ActionTypes.DISABLE_EXECEPTION_OCCURED:
      draft.exception = false
      return
    case ActionTypes.ENABLE_UPLOAD_BUTTON_BY_FILEUPLOAD:
      draft.enableUploadButtonByFileUpload = action.value
      return
    case ActionTypes.IS_UPLOAD_DISABLED:
      draft.isUploadDisabled = action.value
  }
}
