import { BusinessEntity } from "../../types/BusinessEntity"
import { Element } from "../../types/Element"
import ActionTypes from "../ActionTypes"

export interface ManageElementsState {
    entities: BusinessEntity[]
    loadCount: number
    isLoading: boolean
    elementToAddOrEdit: Element | null
    showAddOrEditModal: boolean
    addOrEditModalTitle: string
    elementToChangeStatus: Element
    showChangeElementStatusModal: boolean
    changeStatusCount: number
    allowSubmit: boolean
    isEdit: boolean | null
    saveCount: number
    isSaving: boolean | null
}

export const initialManageElementsState: ManageElementsState = {
    entities: [] as BusinessEntity[],
    loadCount: 0,
    isLoading: false,
    elementToAddOrEdit: {} as Element,
    showAddOrEditModal: false,
    addOrEditModalTitle: "",
    elementToChangeStatus: {} as Element,
    showChangeElementStatusModal: false,
    changeStatusCount: 0,
    allowSubmit: false,
    isEdit: false,
    saveCount: 0,
    isSaving: false
}

export function manageElementsReducer(draft: any, action: any) {
    switch (action.type) {
        case ActionTypes.START_LOADING:
            draft.isLoading = true
            return
        case ActionTypes.STOP_LOADING:
            draft.isLoading = false
            return
        case ActionTypes.CLEAR_OBJECT_LIST:
            draft.entities = [] as BusinessEntity[]
            return
        case ActionTypes.SET_OBJECT_LIST:
            draft.entities = action.value
            return
        case ActionTypes.RELOAD_OBJECTS:
            draft.dataSources = [] as BusinessEntity[]
            draft.loadCount++
            return
    }
}